import React from 'react'
import styled from 'styled-components'
import AuthLeftImageContainer from '../../organisms/Auth/AuthLeftImageContainer'

import FooterImage from '../../atoms/Login/FooterImage'
import { Outlet, useNavigate } from 'react-router-dom'

const AuthContainer = styled.div`
width:100%;
height:100%;
background-color: #F8EDFA;;
min-height:1100px;
display: flex;
flex-direction: column;
overflow-x:hidden;
overflow-y:hidden;
position: relative;
align-items: center;
justify-content: space-between;
position:relative;
`
const AuthPageContainer = styled.div`
width:100%;
height:80%;
gap: 0px;
opacity: 0px;
display: flex;
flex-direction: row;
align-items: center;
justify-content:center;
z-index:2;
`
const FormContainer = styled.div`
display:flex;
flex-direction: column;
position: relative;
// position: absolute;
`
const BottomtextContainer = styled.div`
width:100%;
align-items: center;
display: flex;
justify-content:center;
`
const StyledLink = styled.a`
font-family: Urbanist, sans-serif;
font-size: 23px;
font-weight: 600;
line-height: 25px;
text-align: center;
text-decoration: underline;
margin:24px;
cursor: pointer;
`
const GradientDiv1 = styled.div`
width: 892px;
height: 892px;
position:absolute;
top: 119px;
left: 1028px;
gap: 0px;
opacity: 0.5;
background: radial-gradient(50% 50% at 50% 50%, rgba(90, 81, 191, 0.7) 0%, rgba(90, 81, 191, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

`
const GradientDiv2 = styled.div`
width: 661px;
height: 661px;
position: relative;
top: 784px;
left: 1215px;
opacity: 50%;
background: radial-gradient(50% 50% at 50% 50%, rgba(251, 79, 255, 0.4) 0%, rgba(251, 79, 255, 0) 100%);
overflow: hidden;

`
const GradientDiv3 = styled.div`

width: 516px;
height: 516px;
display: inline-block;
position: relative;
top: -301px;
left: 1622px;
opacity: 50%;
background: radial-gradient(50% 50% at 50% 50%, rgba(251, 79, 255, 0.4) 0%, rgba(251, 79, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

`
const Login: React.FC = () => {
    const navigate = useNavigate();
    return (
        <AuthContainer>

            {/* <GradientDiv1 />
            <GradientDiv2 />
            <GradientDiv3 /> */}
            <AuthPageContainer>

                <AuthLeftImageContainer />
                <FormContainer>

                    <Outlet />
                    <BottomtextContainer >
                        <StyledLink onClick={() => navigate('/extra/privacy-policy')}>Privacy Policy</StyledLink>
                        <StyledLink onClick={() => navigate('/extra/Terms-and-condition')}>Terms of Use</StyledLink>
                    </BottomtextContainer>
                </FormContainer>


            </AuthPageContainer>
            <FooterImage />
        </AuthContainer>
    )
}

export default Login