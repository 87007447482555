import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import TickIconPNG from '../../../../assets/Icons/tick.png'
import TransParentIconPNG from '../../../../assets/Icons/transparent-tick.png'
import { SubscriptionType } from '../../../../Data/subscription.dto';
import CompanyLogoPNG from '../../../../assets/images/Logo.png'
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import { paymentSuccess } from '../../../../Redux/Slice/Dashboard/PaymentSlice';
import GetDurationPopup from './GetPlanDurationPopUp';
import CancelIconPNG from '../../../../assets/Icons/cancelIcon.png'
interface CardProps {
    card: SubscriptionType,
    currentPlanId?: number;
    isActivated: boolean;
    onClick1: (id: number) => void;
    setDurationPopup: (boolean: boolean) => void;
}
const CardContainer = styled.div<{ isActivated: boolean }>`
width: 292px;
height: 500px;
position: relative;
background-color:transparent;
border-radius:20px;
margin-left:15px;
${props => props.isActivated
        ? `
     transform: translateY(-30px);
      box-shadow: 0px 42px 34px 0px #5243C24B;
    transition: all 0.4s ease-in-out;
      background: #231D4F;

    `: null
    }
`;
const RecommendedButton = styled.div <{ isActivated: boolean }>`
display: flex;
align-items: center;
justify-content: center;
${props => !props.isActivated ? `display:none;` : null}
width: 121px;
height: 27px;
background-color:rgb(64,56,121);
color:white;
margin-top:10px;
margin-right:10px;
text-align:center;
border-radius:12px;
opacity:0.7;
padding-top:3px;
font-family: Urbanist;
font-size: 10px;
font-weight: 800;
line-height: 12px;
letter-spacing: 0.8333333134651184px;

text-align: center;

`
const RecommendedContainer = styled.div`

display:flex;
position:absolute;

right:-6px;
align-items: center;
justify-content: end;

`
const CardHeader = styled.div<{ isActivated: boolean }>`
display: flex;
flex-direction: column;
margin-left:30px;
color:${props => props.isActivated ? 'white;' : '#1D1D1D;'}
margin-top:${props => props.isActivated ? '30px;' : '20px;'}
`;

const CardStorage = styled.p`
font-family: Urbanist;
font-size: 36px;
font-weight: 700;
line-height: 46px;
text-align: left;
margin:0px;
`;

const CardPrice = styled.p`
font-family: Urbanist;
font-size: 17px;
font-weight: 600;
line-height: 20.4px;
text-align: left;
margin:0px;

`;

const PlanType = styled.p`
font-family: Urbanist;
font-size: 28px;
font-style: italic;
font-weight: 600;
line-height: 33.6px;
text-align: left;
margin:0px;
`;
const CardDescriptionContainer = styled.div`
display:flex;
flex-direction: column;
height: 255px;
margin-left: 26px;
margin-top: 36px;

`;
const DescriptionItemContainer = styled.div`
display: flex;
margin-top:18px;
align-items: center;

`;
const ItemIcon = styled.img`
width: 20px;
height: 20px;
margin-left:3px;
`;


const CancelIcon = styled.img`
width: 26px;
height: 26px;

`;
const DescriptionItem = styled.p <{ isActivated: boolean }>`
font-family: Urbanist;
font-size: 15px;
font-weight: 500;
line-height: 18px;
text-align: left;
margin: 0px;
margin-left:10px;
color:${props => props.isActivated ? `white` : `#848199`} ;


`;
const ButtonContainer = styled.div`
display:flex;
align-items: center;
justify-content: center;
`;
const SubmitButton = styled.button<{ isActivated: boolean }>`
width: 207px;
height: 45px;
color:white;
border:none;
border-radius:20px;
font-family: Urbanist;
font-size: 15px;
font-weight: 500;
line-height: 18px;
text-align: center;
cursor:pointer;

background:${props => props.isActivated ? 'linear-gradient(360deg, #7A11A1 0%, #C62BC9 100%);' : '#8b85A4'};
${props => props.isActivated ? `box-shadow: 0px 4px 18px 0px #A720B966;` : null}
`;


const CanceledText = styled.span`
text-decoration:line-through;
`;
const SubscriptionCard: React.FC<CardProps> = ({ card, isActivated, onClick1, setDurationPopup, currentPlanId }) => {



    const formatToINR = (amount: number): string => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0,
        }).format(amount);
    };


    return (
        <CardContainer isActivated={isActivated} onClick={() => onClick1(card.id)}>
            <RecommendedContainer>
                {currentPlanId && currentPlanId === card.id ?
                    (<RecommendedButton isActivated={true}>Current</RecommendedButton>) :
                    (<RecommendedButton isActivated={card.description === 'MOST POPULAR'}>Recommended</RecommendedButton>)
                }

            </RecommendedContainer>
            <CardHeader isActivated={isActivated}>
                <CardStorage>
                    {card.storage_size}
                </CardStorage>
                <CardPrice>
                    {formatToINR(card.offer_price * card.plan_pack_duration)} (<CanceledText >{formatToINR(card.price_per_month * card.plan_pack_duration)}</CanceledText>)  / {card.plan_pack_duration} Months
                </CardPrice>
                <PlanType>
                    {card.description}
                </PlanType>
            </CardHeader>
            <CardDescriptionContainer>
                <DescriptionItemContainer>
                    <ItemIcon src={TickIconPNG} />

                    <DescriptionItem isActivated={isActivated}>
                        {card.project_limit} Albums
                    </DescriptionItem>
                </DescriptionItemContainer>
                <DescriptionItemContainer>
                    <ItemIcon src={TickIconPNG} />

                    <DescriptionItem isActivated={isActivated}>
                        Share  to {card.project_limit} People
                    </DescriptionItem>
                </DescriptionItemContainer>
                <DescriptionItemContainer>
                    {!card.event_management ? <CancelIcon src={CancelIconPNG} /> : <ItemIcon src={TickIconPNG} />}

                    <DescriptionItem isActivated={isActivated}>
                        Event Scheduling
                    </DescriptionItem>
                </DescriptionItemContainer>
                <DescriptionItemContainer>
                    {!card.packages ? <CancelIcon src={CancelIconPNG} /> : <ItemIcon src={TickIconPNG} />}

                    <DescriptionItem isActivated={isActivated}>
                        Studio Packages
                    </DescriptionItem>
                </DescriptionItemContainer>
                <DescriptionItemContainer>
                    {!card.user_form ? <CancelIcon src={CancelIconPNG} /> : <ItemIcon src={TickIconPNG} />}

                    <DescriptionItem isActivated={isActivated}>
                        Customer Event Management
                    </DescriptionItem>
                </DescriptionItemContainer>





            </CardDescriptionContainer>
            <ButtonContainer>
                <SubmitButton isActivated={isActivated} onClick={(e) => { !isActivated ? onClick1(card.id) : setDurationPopup(true) }}>Choose Plan</SubmitButton>
            </ButtonContainer>
        </CardContainer>
    )
}

export default SubscriptionCard