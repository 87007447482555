import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface FlagState {
    loading: boolean;
    CalendarEventFlag: boolean;
    Package: boolean;
    UserEvent: boolean;
    UserTransaction: boolean;
    Quotation: boolean;

}

const initialState: FlagState = {
    loading: false,
    CalendarEventFlag: false,
    Package: false,
    UserEvent: false,
    UserTransaction: false,
    Quotation: false
}



const flagSlice = createSlice({
    name: 'flags',
    initialState,
    reducers: {
        setCalendarEventFlag(state, action: PayloadAction<{ value: boolean }>) {
            state.CalendarEventFlag = action.payload.value;
        },
        setPackageFlag(state, action: PayloadAction<{ value: boolean }>) {
            state.Package = action.payload.value;
        },
        setUserEventFlag(state, action: PayloadAction<{ value: boolean }>) {
            state.UserEvent = action.payload.value;
        },

        setUserTransactionFlag(state, action: PayloadAction<{ value: boolean }>) {
            state.UserTransaction = action.payload.value;
        },
        setQuotationFlag(state, action: PayloadAction<{ value: boolean }>) {
            state.Quotation = action.payload.value;
        },

    },
    extraReducers: (builder) => {

    },
});

export const { setCalendarEventFlag, setPackageFlag, setUserEventFlag, setUserTransactionFlag, setQuotationFlag } = flagSlice.actions;


export default flagSlice.reducer;
