import React, { useEffect, useState } from 'react'
import SubscriptionCard from '../../atoms/Dashboard/Subscription/SubscriptionCard'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks'
import { subscriptionsPlansAPI } from '../../../Redux/ApiCalls/Dashboard/SubscriptionAPI'
import { SubscriptionType } from '../../../Data/subscription.dto'
import LoadingDots from '../../atoms/Utlis/LoadinDots'
import GetDurationPopup from '../../atoms/Dashboard/Subscription/GetPlanDurationPopUp'
import {
    completePaymentAPI,
    createOrderAPI,
} from '../../../Redux/ApiCalls/Dashboard/PaymentAPI'
import CompanyLogoPNG from '../../../assets/images/Logo.png'
import { cancelPayment } from '../../../Redux/Slice/Dashboard/PaymentSlice'
import CurrentPlan from '../../atoms/Dashboard/Subscription/CurrentPlan'
import { getUserActivePlanAPI } from '../../../Redux/ApiCalls/Auth/user'
import SubmitButton from '../../atoms/Login/SubmitButton'
import sendFirebaseEvent from '../../../analytics/FirebaseAnalytics'

const SubscriptionPage: React.FC = () => {
    const dispatch = useAppDispatch()
    const { subscriptions, loading } = useAppSelector((state) => state.extra)
    const [activeCard, setActiveCard] = useState<number>()
    const [durationPopup, setDurationPopup] = useState(false)
    const [duration, setDuration] = useState<number>()
    const [currentCard, setCurrentCard] = useState<SubscriptionType>()
    const { order, paymentSuccess } = useAppSelector((state) => state.payment)
    const { user, currentActivePlan, isCurrentPlanChanged } = useAppSelector(
        (state) => state.auth,
    )
    const [showCurrentPlan, setShowCurrentPlan] = useState<boolean>(false)
    useEffect(() => {
        sendFirebaseEvent(
            'Subscription',
            'Page_Preview',
            'Load_Subscription_Page',
            'Load_Subscription_Page',
        )
    }, [])
    useEffect(() => {
        if (!subscriptions) {
            dispatch(subscriptionsPlansAPI())
        } else {
            subscriptions.forEach((sub: SubscriptionType) => {
                if (sub.description === 'MOST POPULAR') {
                    setActiveCard(sub.id)
                    setCurrentCard(sub)
                }
            })
        }

        return () => { }
    }, [subscriptions, dispatch])
    useEffect(() => {
        if (order && !paymentSuccess && currentCard && duration) {
            setDurationPopup(false)
            console.log(order.amount)
            const options = {
                key: `${process.env.REACT_APP_RAZORPAY}`,
                currency: 'INR',
                name: 'Kwik Media',
                description: `${currentCard.description}`,
                subscription_id: order.id,
                handler: async function (response: any) {

                    sendFirebaseEvent(
                        'Subscription',
                        'User_Interaction',
                        'Payment_Completed',
                        `plan_id : ${currentCard.id}`,
                    )
                    dispatch(
                        completePaymentAPI({
                            plan_id: currentCard.id,
                            duration,
                            payment_id: response.razorpay_payment_id,
                            order_id: response.razorpay_subscription_id,
                            signature: response.razorpay_signature,
                            settlement_for: 'plan',
                        }),
                    )
                },
                prefill: {
                    name: `${user?.name}`,
                    email: `${user?.email}`,
                    contact: `${user?.phone_number}`,
                },
                notes: {
                    address: ' Kwik Media Address.',
                },
                theme: {
                    color: '#AE2AB1',
                },
            }

            const rzp = new (window as any).Razorpay(options)

            rzp.on('payment.failed', (response: any) => {
                console.log('payment failed', response)
            })

            rzp.on('payment.cancelled', () => {
                alert('Payment cancelled');
                dispatch(cancelPayment())
                // Handle payment cancellation
            })
            rzp.open()
        }
    }, [order, paymentSuccess, dispatch])
    useEffect(() => {
        if (isCurrentPlanChanged) {
            dispatch(getUserActivePlanAPI())
        }
        if (!isCurrentPlanChanged && currentActivePlan) {
            setShowCurrentPlan(true)
        }
    }, [isCurrentPlanChanged, currentActivePlan])
    const handleActiveCard = (id: number) => {
        if (subscriptions) {
            subscriptions.forEach((sub: SubscriptionType) => {
                if (sub.id === id) {
                    setActiveCard(sub.id)
                    setCurrentCard(sub)
                }
            })
        }
    }
    const startPayment = (id: number, duration: number) => {
        setDuration(duration)
        dispatch(
            createOrderAPI({ storage_plan_id: id, duration, settlement_for: 'plan' }),
        )
        sendFirebaseEvent(
            'Subscription',
            'User_Interaction',
            'Start_Payment',
            `plan_id : ${id}`,
        )
    }

    return (
        <PageContainer>
            {durationPopup && currentCard && (
                <GetDurationPopup
                    cancel={() => setDurationPopup(false)}
                    Buy={(id, duration) => {
                        startPayment(id, duration)
                    }}
                    card={currentCard}
                />
            )}

            <Container>
                <HeadingContainer>
                    <Heading>Plans & Pricing</Heading>
                    <SubHeading>
                        Whether your time-saving automation needs are large or small, we’re
                        here to help you scale.
                    </SubHeading>
                </HeadingContainer>

                <PlanContainer>
                    {showCurrentPlan && currentActivePlan ? (
                        <CurrentPlan plan={currentActivePlan} />
                    ) : loading ? (
                        <LoadingContainer>
                            <LoadingDots />
                        </LoadingContainer>
                    ) : !loading && subscriptions ? (
                        subscriptions?.map((subscription) => (
                            <SubscriptionCard
                                card={subscription}
                                currentPlanId={currentActivePlan?.storage_plan_id.id}
                                isActivated={activeCard === subscription.id}
                                onClick1={handleActiveCard}
                                setDurationPopup={setDurationPopup}
                            />
                        ))
                    ) : null}
                </PlanContainer>
                <ButtonContainer>
                    {currentActivePlan && (
                        <SubmitButton
                            text={showCurrentPlan ? 'Explore More Plans' : 'Current Plan'}
                            width={220}
                            needArrow={false}
                            onClick={() => {
                                if (!showCurrentPlan) {
                                    sendFirebaseEvent('Subscription', 'User_Interaction', 'Explore_Plan_Button_Clicked', `Explore_Plan_Button_Clicked`)

                                }
                                setShowCurrentPlan((pre) => !pre)
                            }}
                        />
                    )}
                </ButtonContainer>
            </Container>
        </PageContainer>
    )
}

export default SubscriptionPage

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
`
const HeadingContainer = styled.div``
const Heading = styled.p`
  font-family: Urbanist;
  font-size: 38px;
  font-weight: 600;
  line-height: 45.6px;
  text-align: left;
`
const SubHeading = styled.p`
  font-family: Urbanist;
  font-size: 23px;
  font-weight: 500;
  line-height: 27.6px;
  text-align: left;
`
const PlanContainer = styled.div`
  width: 937px;
  height: 500px;
  background: #ffffff80;
  margin-top: 90px;
  border-radius: 20px;
  display: flex;
`
const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ButtonContainer = styled.div`
  margin-top: 30px;
`
