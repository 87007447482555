import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ViewIconPNG from '../../../../assets/Icons/view.png'
import EditIconPNG from '../../../../assets/Icons/editPackage.png'
import PlusSignIconPNG from '../../../../assets/Icons/addIcon.png'
import UnderLine from '../../../atoms/Login/UnderLine'
import QuotationEventModal from '../../../atoms/Dashboard/Quotation/QuotationEventModal'
import { QuotationEventType } from '../../../../Data/quotation.dto'

interface QuotationEventsPropsType {
  handlePreviousScreen: () => void;
  quotationEvents: QuotationEventType[];
  handleQuotationEvents: (data: QuotationEventType[]) => void;
}


const QuotationEvents: React.FC<QuotationEventsPropsType> = ({
  handlePreviousScreen,
  quotationEvents,
  handleQuotationEvents
}) => {
  const navigate = useNavigate()
  const [quotationEventModal, setQuotationEventModal] = useState<boolean>(false);
  const [newQuotationEvents, setNewQuotationEvents] = useState<QuotationEventType[]>(quotationEvents ?? []);
  const [selectedEvent, seSelectedEvent] = useState<QuotationEventType | undefined>();
  const [isNewEvent, setIsNewEvent] = useState<boolean>(true);
  const handleAddNewQuotationEvent = (event: QuotationEventType, isNew: boolean) => {
    // console.log("event data", event)
    if (isNew) {
      console.log("event id", event.id);
      if (event.id !== undefined) {
        console.log("updating", event)
        setNewQuotationEvents(pre => pre.map(e => {
          if (event.id === e.id) {
            return event;
          }
          return e;
        }))
      } else {
        event.id = newQuotationEvents.length;
        console.log("creating", event)

        setNewQuotationEvents(pre => [...pre, event])
      }

    } else {

    }

  }

  const onSubmit = () => {
    handleQuotationEvents(newQuotationEvents);
  }
  return (
    <Container>
      {quotationEventModal && <QuotationEventModal onSubmit={(data, isNew) => handleAddNewQuotationEvent(data, isNew)} onCancel={() => setQuotationEventModal(false)} eventData={selectedEvent} isNew={isNewEvent} />}
      <HeaderContainer>
        <HeaderText>Customer Events</HeaderText>
      </HeaderContainer>
      <BodyContainer>
        <AddButtonContainer>
          <AddEventButton
            onClick={() => {
              seSelectedEvent(undefined);
              setIsNewEvent(true);
              setQuotationEventModal(true);
            }}
          >
            <PlusSignContainer>
              <PlusSignIcon src={PlusSignIconPNG}></PlusSignIcon>
            </PlusSignContainer>
            <ButtonText>NEW</ButtonText>
          </AddEventButton>
        </AddButtonContainer>

        <EventHeaderContainer>
          <EventNameHeading>Event Name</EventNameHeading>
          <EventDateHeading>Date</EventDateHeading>
          <EventTimeHeading>Time</EventTimeHeading>

        </EventHeaderContainer>
        <UnderLine width={100} isPercent={true} />

        <EventListContainer>

          {newQuotationEvents.map(event => (
            <EventContainer>
              <EventName>{event.function_name}</EventName>
              <EventDate>{event.function_date}</EventDate>
              <EventTime>{event.function_time}</EventTime>
              <EventActionContainer>
                <SeeEventIconContainer>
                  <SeeEventIcon src={ViewIconPNG} />
                </SeeEventIconContainer>

                <UpdateEventIconContainer>
                  <UpdateEventIcon src={EditIconPNG} onClick={() => {
                    seSelectedEvent(event);
                    setIsNewEvent(true);
                    setQuotationEventModal(true);
                  }} />
                </UpdateEventIconContainer>
              </EventActionContainer>
            </EventContainer>
          ))}
        </EventListContainer>
      </BodyContainer>
      <ButtonContainer>
        <PreviousButton onClick={() => handlePreviousScreen()}>Previous</PreviousButton>
        <NextButton onClick={() => onSubmit()}>Next</NextButton>
      </ButtonContainer>
    </Container>
  )
}

export default QuotationEvents
const Container = styled.div``;

const HeaderText = styled.div`
font-family: Urbanist,sans-serif;
font-size: 24px;
font-weight: 600;
line-height: 22.8px;
text-align: center;
color: #171717;
margin:0px;
margin-left:11px;
`;

const HeaderContainer = styled.div`
width:100%;

`;


const EventContainer = styled.div`
display:flex;
margin-top:20px;
padding-bottom:5px;
border-bottom:2px solid #e9e9e9;
`;

const EventNameHeading = styled.div`
  font-family: 'Urbanist', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
    width:35%;
`;

const EventDateHeading = styled.div`
font-family: 'Urbanist', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
    width:25%;
    `;

const EventTimeHeading = styled.div`
font-family: 'Urbanist', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
    width:25%;
    `;


const AddButtonContainer = styled.div`
width:100%;

`;

const BodyContainer = styled.div`
display:flex;
flex-direction: column;
height:580px;
overflow-y: auto;
overflow-x: hidden;
`;


const AddEventButton = styled.button`
  border: 1px solid #a720b9;
  width: 80px;
  height: 36px;
  margin-right: 52px;
  border: 1px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid #a720b9;
  cursor: pointer;
  float:right;
`
const PlusSignContainer = styled.div``
const PlusSignIcon = styled.img`
  height: 21px;
  width: 21px;
`

const ButtonText = styled.div`
  width: 29px;
  height: 17px;
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  margin-left:5px;
  text-align: left;
  color: #a720b9;
`

const EventHeaderContainer = styled.div`

display:flex;
margin-top:20px;s
`;

const EventName = styled.div`
 font-family: 'Urbanist', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
    width:35%;
`;

const EventDate = styled.div`
font-family: 'Urbanist', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
    width:25%;
`;

const EventTime = styled.div`
font-family: 'Urbanist', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
    width:25%;
`;

const EventActionContainer = styled.div`
display:flex;
align-items: center;
justify-content: center;
`;

const SeeEventIconContainer = styled.div``;

const UpdateEventIconContainer = styled.div`
margin-left:20px;`;

const SeeEventIcon = styled.img`

height:28px;
width:28px;
`;

const UpdateEventIcon = styled.img`
height:25px;
width:28px;
`;


const EventListContainer = styled.div`
margin-top: 40px;
`;


const ButtonContainer = styled.div`
width: 100%;
display:flex;
align-items: center;
justify-content: center;
font-family: 'Montserrat', sans-serif;
  
  line-height: 17.07px;
`;

const PreviousButton = styled.button`
width:120px;
padding:10px;
background-color:#c5c5c5;
color:white;
font-size: 18px;
  font-weight: 600;
  margin-right:30px;
border:none;
border-radius:15px;
cursor:pointer;
&:hover{
background-color:#adadad;
}

`;


const NextButton = styled.button`
width:120px;
padding:10px;
background-color:#AE2AB1;
color:white;
font-size: 18px;
  font-weight: 600;
  margin-right:30px;
border:none;
border-radius:15px;
cursor:pointer;
&:hover{
background-color:#c433c7;
}
`;