import React, { useState } from 'react'
import styled from 'styled-components'
import UnderLine from '../../../atoms/Login/UnderLine'
import Errortext from '../../../atoms/Utlis/Errortext'
import { QuotationCustomer } from '../../../../Data/quotation.dto'

interface CustomerDataFormProps {
  handleCustomerData: (data: QuotationCustomer) => void;
  customerData?: QuotationCustomer;
  handlePreviousScreen: () => void;
}

const CustomerDataForm: React.FC<CustomerDataFormProps> = ({ handleCustomerData, customerData, handlePreviousScreen }) => {
  const [name, setName] = useState<string | undefined>(customerData?.name)
  const [phone, setPhone] = useState<string | undefined>(customerData?.phone)
  const [place, setPlace] = useState<string | undefined>(customerData?.place)
  const [address, setAddress] = useState<string | undefined>(customerData?.address)
  const [reference, setReference] = useState<string | undefined>(customerData?.reference)
  const [showError, setShowError] = useState(false);
  const onChangeData = (name: string, value: string) => {
    switch (name) {
      case 'name':
        if (value.length <= 30) setName(value);
        break;
      case 'phone':
        if (value.length <= 10) setPhone(value);
        break;
      case 'place':
        if (value.length <= 100) setPlace(value);
        break;
      case 'address':
        if (value.length <= 150) setAddress(value);
        break;
      case 'reference':
        if (value.length <= 30) setReference(value);
        break;
      default:
        break;
    }
  };
  const validPhoneNumber = (number: string) => {
    const phoneRegex = /^[0-9]{10}$/
    return phoneRegex.test(number)
  }

  const validData = (key: string,) => {

    if (key == 'name') return name && name.length > 0 && name.length <= 30;
    else if (key == 'place') return place && place.length > 0 && place?.length <= 100;
    else if (key == 'address') return address && address.length > 0 && address.length <= 100;
    else if (key == 'phone') return phone && validPhoneNumber(phone) && phone.trim().length > 0 && phone.length <= 10;
    else if (key == 'reference') return reference && reference.length > 0 && reference.length <= 30;
    else true;
  }

  const onSubmit = () => {

    if (name && validData("name")
      && place && validData("place")
      && address && validData("address")
      && phone && validData("phone")) {
      handleCustomerData({
        name,
        address,
        reference,
        phone,
        place
      })
    } else {
      setShowError(true);
    }
  }
  return (
    <Container>
      <HeaderContainer>
        <HeaderText>Customer Data</HeaderText>
      </HeaderContainer>
      <BodyContainer>
        <FormContainer>
          <InputContainer>
            <Label>Customer Name : </Label>
            <Input type='text' value={name} onChange={(e) => onChangeData("name", e.target.value)} />
            <UnderLine width={100} isPercent={true} />
            <Errortext show={showError && !validData("name")} message='Please provide valid customer name' />
          </InputContainer>
          <InputContainer>
            <Label>Phone Number : </Label>
            <Input type='number' value={phone} onChange={(e) => onChangeData("phone", e.target.value.toString())} />
            <UnderLine width={100} isPercent={true} />
            <Errortext show={showError && !validData("phone")} message='Please provide valid phone number' />
          </InputContainer>
          <InputContainer>
            <Label>Place : </Label>
            <Input type='text' value={place} onChange={(e) => onChangeData("place", e.target.value)} />
            <UnderLine width={100} isPercent={true} />
            <Errortext show={showError && !validData("place")} message='Please provide valid place' />
          </InputContainer>
          <InputContainer>
            <Label>Address : </Label>
            <Input type='text' value={address} onChange={(e) => onChangeData("address", e.target.value)} />
            <UnderLine width={100} isPercent={true} />
            <Errortext show={showError && !validData("address")} message='Please provide valid address' />
          </InputContainer>
          <InputContainer>
            <Label>Reference : </Label>
            <Input type='text' value={reference} onChange={(e) => onChangeData("reference", e.target.value)} />
            <UnderLine width={100} isPercent={true} />

          </InputContainer>
        </FormContainer>

      </BodyContainer>
      <ButtonContainer>
        <PreviousButton onClick={() => handlePreviousScreen()}>Previous</PreviousButton>
        <NextButton onClick={() => onSubmit()}>Next</NextButton>
      </ButtonContainer>

    </Container>
  )
}

export default CustomerDataForm

const Container = styled.div``;

const HeaderText = styled.div`

font-family: Urbanist,sans-serif;
font-size: 24px;
font-weight: 600;
line-height: 22.8px;
text-align: center;
color: #171717;
margin:0px;
margin-left:11px;
`;

const HeaderContainer = styled.div`
width:100%;

`;

const FormContainer = styled.div`
display:flex;
flex-wrap:wrap;
margin-top:50px;
justify-content:space-between;
height:200px;

`;

const InputContainer = styled.div`
width:45%;
margin-bottom:40px;
display:flex;
flex-direction:column;
`;
const BodyContainer = styled.div`
height:580px;
`;
const Label = styled.label`
font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #424242;
`;

const Input = styled.input`
margin-top: 7px;
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;

  border: none;

&::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
`;

const ButtonContainer = styled.div`
width: 100%;
display:flex;
align-items: center;
justify-content: center;
font-family: 'Montserrat', sans-serif;
  
  line-height: 17.07px;
`;

const PreviousButton = styled.button`
width:120px;
padding:10px;
background-color:#c5c5c5;
color:white;
font-size: 18px;
  font-weight: 600;
  margin-right:30px;
border:none;
border-radius:15px;
cursor:pointer;
&:hover{
background-color:#adadad;
}

`;


const NextButton = styled.button`
width:120px;
padding:10px;
background-color:#AE2AB1;
color:white;
font-size: 18px;
  font-weight: 600;
  margin-right:30px;
border:none;
border-radius:15px;
cursor:pointer;
&:hover{
background-color:#c433c7;
}
`;
