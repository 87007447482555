// features/payment/paymentSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Error } from '../../../Data/error.dto';
import { QuotationReducer } from '../../Reducers/Dashboard/QuotationReducer';

export interface QuotationState {
    loading: boolean;
    allQuotations: any[];
    currentQuotation?: any;
    error: Error;
    isError: boolean;
}

const initialState: QuotationState = {
    loading: false,
    allQuotations: [],
    currentQuotation: null,
    error: {},
    isError: false,
};


const QuotationSlice = createSlice({
    name: 'quotation',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        QuotationReducer(builder)
    },
});

export const { } = QuotationSlice.actions;
export default QuotationSlice.reducer;
