import React, { useEffect, } from 'react';
import './App.css';

import Auth from './components/pages/Auth/Auth';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Redux/Store';
import LoginForm from './components/organisms/Auth/Login/LoginForm';
import OtplVerificationForm from './components/organisms/Auth/OtpVerification/OtpVerificationForm';
import RegisterForm from './components/organisms/Auth/Register/RegisterForm';
import Dashboard from './components/pages/Dashboard/Dashboard';
import HomePage from './components/organisms/Dashboard/HomePage';
import SingleAlbum from './components/organisms/Dashboard/SingleAlbumPage';
import AllMembersPage from './components/organisms/Dashboard/AllMembersPage';
import CreateNewMemberPage from './components/molecules/Dashboard/Member/CreateNewMember';
import Albums from './components/molecules/Dashboard/HomePage/Albums';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import SubscriptionPage from './components/organisms/Dashboard/SubscriptionPage';
import ShareCodePage from './components/organisms/Dashboard/ShareCodePage';
import UpdateFolderPage from './components/molecules/Dashboard/SingleAlbumPage/UpdateFolderPage';
import { setFCM, updateNotification } from './Redux/Slice/Dashboard/ExtraSlice';
import EditMemberPage from './components/molecules/Dashboard/Member/EditMember';
import EventCalendar from './components/organisms/Dashboard/EventCalendar';
import { app, messaging, remoteConfig } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";
import ChangePhoneNumber from './components/organisms/Dashboard/ChangePhoneNumber';
import AllNotificationPage from './components/organisms/Dashboard/AllNotificationPage';
import RedeemUser from './components/atoms/Dashboard/HomePage/RedeemUser';
import AllPackagesPage from './components/organisms/Dashboard/AllPackagesPage';
import AddNewPackagePage from './components/molecules/Dashboard/Package/AddNewPackagePage';
import EventForm from './components/pages/ShareCreateEventForm/EventForm';
import UserCreatedEvents from './components/organisms/Dashboard/UserCreatedEvents';
import SubEventPage from './components/molecules/Dashboard/UserEvents/SubEventPage';
import UserProfilePage from './components/organisms/Dashboard/UserProfilePage';
import { fetchAllRemoteConfig } from './Utils/RemoteConfig';
import AllCustomerTransactionPage from './components/organisms/Dashboard/AllCustomerTransactionPage';
import { useLoadScript } from '@react-google-maps/api';
import PrivacyPolicy from './components/pages/Extra/PrivacyPolicy';
import TermsCondition from './components/pages/Extra/TermsAndCondition';
import AllQuotationPage from './components/organisms/Dashboard/AllQuotationPage'
import SingleQuotationPage from './components/organisms/Dashboard/SingleQuotationForm';

function App() {

  const navigate = useNavigate();

  onMessage(messaging, (payload) => {

    store.dispatch(updateNotification({ update: true, count: 1 }));

  });
  useEffect(() => {


    async function requestPermission() {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {

        const token = await getToken(messaging, {
          vapidKey:
            "BLlRcimfpiB0wFXdmp2OGVHx5hGyMOjgke1yNtpokahKnMRmpbR-u5brlcoEUyGlHbrci-AdBqSku1GosO6X6yg",
        });

        store.dispatch(setFCM(token));
      } else if (permission === "denied") {
      }
    }
    // 
    requestPermission();
  }, []);
  useEffect(() => {

    if ('serviceWorker' in navigator) {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
          .then((registration) => {
            // console.log('Service Worker registered with scope:', registration.scope);
          })
          .catch((err) => {
            console.error('Service Worker registration failed:', err);
          });

        // Listen for messages from the service worker
        navigator.serviceWorker.addEventListener('message', (event) => {
          // console.log('Message received from service worker:', event);
          if (event.data && event.data.msg === 'backgroundMessage') {
            const payload = event.data.data;
            store.dispatch(updateNotification({ update: true, count: 1 }));
            // console.log('Background message received in React app:', payload);

            // Handle the payload as needed in your React app
          }
        });
      }
    }

  }, []);

  const location = useLocation();

  useEffect(() => {
    // alert("called")
    // remoteConfig.fetchAndActivate()
    fetchAllRemoteConfig()
    const path = location.pathname;
    // console.log(path)
    if (path.startsWith("/share/form") || path.startsWith("/extra")) {
      return;
    }
    else if (!localStorage.getItem('access_token')) {

      navigate('/auth/login');
    } else {
      navigate('/dashboard/')
    }


  }, [])

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDrb_5q1m_fYiBjtx53rYSZskDa6Ns5JNw',
    libraries: ['places'],
  });

  return (
    <Provider store={store}>
      <div className="App">
        <Routes>
          <Route path="/auth" element={<Auth />}>
            <Route path="otp" element={<OtplVerificationForm />} />
            <Route path="signup" element={<RegisterForm />} />
            <Route path="login" element={<LoginForm />} />
          </Route>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route index element={<HomePage />} />
            <Route path="singleAlbum/:new" element={< SingleAlbum />} />
            <Route path="members/all" element={<AllMembersPage />} />
            <Route path="members/create/new" element={<CreateNewMemberPage />} />
            <Route path="members/edit/:id" element={<EditMemberPage />} />
            <Route path="albums/all" element={<Albums />} />
            <Route path="albums/folder/:id" element={<UpdateFolderPage />} />
            <Route path="subscriptions" element={<SubscriptionPage />} />
            <Route path="albums/share/:code" element={<ShareCodePage />} />
            <Route path="albums/redeemUsers/:id" element={<RedeemUser />} />
            <Route path="event" element={<EventCalendar />} />
            <Route path="events/userCreated" element={<UserCreatedEvents />} />
            <Route path="events/userCreated/single" element={<SubEventPage />} />

            <Route path="user/changePhoneNumber" element={<ChangePhoneNumber />} />
            <Route path="user/profile" element={<UserProfilePage />} />
            <Route path="user/allNotifications" element={<AllNotificationPage />} />
            <Route path="package/single" element={<AddNewPackagePage />} />
            <Route path="packages/all" element={<AllPackagesPage />} />
            <Route path="user/transactions" element={<AllCustomerTransactionPage />} />
            <Route path="quotation/" element={<AllQuotationPage />} />
            <Route path="quotation/singleQuotation" element={<SingleQuotationPage />} />

          </Route>
          <Route path="/share/form/:data" element={<EventForm />} />
          <Route path="/extra/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/extra/Terms-and-condition" element={<TermsCondition />} />
        </Routes>
        <ToastContainer />
      </div>
    </Provider>
  );
}

export default App;
