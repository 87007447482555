import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SubmitButton from '../../Login/SubmitButton'
import { RedeemUsers } from '../../../../Data/user.dto'

interface ComponentProps {
    redeemUser: RedeemUsers[],
    closeModal: () => void;
    showFunction: (id: number) => void;
}

const SelectRedeemUserListPopup: React.FC<ComponentProps> = ({ redeemUser, closeModal, showFunction }) => {
    const [selectedUserId, setSelectedUserId] = useState<number>();
    useEffect(() => {
        if (redeemUser.length > 0) {
            for (let i = 0; i < redeemUser.length; i++) {
                if (redeemUser[i].is_authorized === "True") {
                    setSelectedUserId(redeemUser[i].id);
                    break;
                }
            }
        }

    }, [])
    return (
        <ModalOverlay>
            <ModalContent onClick={(e) => { e.stopPropagation() }}>
                <ModalHeading>Redeem Users</ModalHeading>
                <ReasonListContainer>
                    {redeemUser.length === 0 ?
                        <div style={{ display: "flex", width: "100%", alignContent: "center", justifyContent: "center" }}>No Redeem Users.</div>
                        : redeemUser.map((user) => (

                            <UserLabelContainer>
                                <UserInput
                                    type="radio"
                                    id={user.name}
                                    value={user.id}
                                    checked={selectedUserId === user.id}
                                    onChange={() => setSelectedUserId(user.id)}
                                />
                                <UserLabel htmlFor={user.name}>
                                    {user.name}
                                </UserLabel>

                            </UserLabelContainer>
                        ))
                    }




                </ReasonListContainer>
                <SubmitButtonContainer >
                    {/* {loading ?


<LoadingDots position={{ type: "absolute", top: "41px", left: "135px" }} />

: */}


                    <SubmitButton onClick={() => { if (selectedUserId) showFunction(selectedUserId) }} width={150} text='Show' needArrow={false} active={false} />
                    <CloseButton onClick={() => closeModal()} >Cancel</CloseButton>
                    {/* } */}

                </SubmitButtonContainer>
            </ModalContent>
        </ModalOverlay>
    )
}

export default SelectRedeemUserListPopup



const ModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index:1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  background-color: white;
color: black;
font-family: Urbanist,sans-serif;

  padding: 20px;
  border-radius: 5px;
  width: 450px;
  height:350px;
  max-width: 80%;
  position: relative;
`;

const CloseButton = styled.button`
width:150px;
height:54px;
border:none;
border-radius: 16px 16px 16px 16px;
font-family: Urbanist, sans-serif;
font-size: 16px;
font-weight: 500;
line-height: 19.2px;
text-align: center;
color: black;
margin-left:30px;
background-color:#bcbaba;

`;

const Textarea = styled.input`
  width: 100%;
  height: 40px;
  margin-top: 10px;
  border-radius: 7px;
`;

const SubmitButtonContainer = styled.div`
margin-top:25px;
display:flex;
align-items: center;
justify-content: center;
`
const ModalHeading = styled.div`
width: 100%;
height: 34px;
font-family: Urbanist,sans-serif;
font-size: 28px;
font-weight: 600;
line-height: 34px;
text-align: center;
margin-bottom:20px;
`

const ReasonListContainer = styled.div`
display :flex;
flex-direction: column;
justify-content: left;
align-items: left;
height:228px;
overflow-y: auto;
`
const UserLabelContainer = styled.label`
text-align: left;
display:flex;
flex-direction: row;
align-items: center;
width: 100%;
justify-content:center;
margin-bottom:5px;
`

const UserLabel = styled.label`
font-family: Urbanist,sans-serif;
font-size: 18px;
width:100%;
font-weight: 500;
line-height: 34px;
text-align: left;
margin-left:10px;
cursor:pointer;

`
const UserInput = styled.input`
  width: 20px;
  height: 20px;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: white;
 &:checked::before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #AE2AB1; /* Change this to your desired color */
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-50%, -50%);
  }
`