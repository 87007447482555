import { createAsyncThunk } from "@reduxjs/toolkit";
import apiCall from "../AuthorizedApi";
import axios from "axios";
import { getHostUrl } from "../getHostUrl";
export const createQuotationAPI = createAsyncThunk(
    'quotation/createQuotationAPI',
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'POST',
                url: `/plans/customer-quotation/`,
                data

            })

            // console.log(response.data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
export const getAllQuotationAPI = createAsyncThunk(
    'quotation/getAllQuotationAPI',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'GET',
                url: `/plans/customer-quotation/`,


            })

            // console.log(response.data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });

