import { createAsyncThunk } from "@reduxjs/toolkit";
import apiCall from "../AuthorizedApi";
export const getAllUnseenNotificationAPI = createAsyncThunk(
    'extra/getAllUnseenNotificationAPI',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'GET',
                url: '/account/notification/?notification_type=unseen'
            })
            // console.log(response.data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });

export const getAllNotification = createAsyncThunk(
    'extra/getAllNotification',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'GET',
                url: `/account/notification/?notification_type=all`
            })
            // console.log(response.data);
            return { data: response.data, total_items: response.total_items, current_page: response.page };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
export const seenNotification = createAsyncThunk(
    'extra/seenNotification',
    async (data: { notification_ids: number[] }, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'PUT',
                url: '/account/notification/',
                data
            })
            // console.log(response.data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });