import { createAsyncThunk } from "@reduxjs/toolkit";
import apiCall from "../AuthorizedApi";
import store from "../../Store";
import { updateTotalProgress } from "../../Slice/Dashboard/AlbumSlice";
import { showErrorToast } from "../../../components/atoms/Utlis/Toast";
export const getFoldersForAlbum = createAsyncThunk(
    'album/getFoldersForAlbum',
    async (albumId: number, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'GET',
                url: `/project/user/get-folder/?project_id=${albumId}&fetch=20`
            })
            // console.log(response);
            return { folders: response.data, album_id: albumId };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
export const createFolderAPI = createAsyncThunk(
    'album/createFolderAPI',
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'POST',
                url: `/project/user/project/create-folder/`,
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data: data
            })
            // console.log(response);
            return { folder_id: response.data.folder_id, project_id: response.data.project_id };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
export const updateFolderAPI = createAsyncThunk(
    'album/updateFolderAPI',
    async (data: { id: number, project_id: number, folder_name: string, folderImages: File[] }, { rejectWithValue }) => {
        try {


            const files = data.folderImages;
            console.log("images length")
            console.log(data.folderImages.length)
            const chunkSize = 10;
            const fileChunks = [];

            for (let i = 0; i < files.length; i += chunkSize) {
                fileChunks.push(files.slice(i, i + chunkSize));
            }

            let uploadedFilesCount = 0;
            store.dispatch(updateTotalProgress({
                folderId: data.id, folderName: data.folder_name,
                totalProgress: 1
            }));
            for (const chunk of fileChunks) {
                const formData = new FormData();
                formData.append('folder_name', data.folder_name);
                formData.append('folder_id', `${data.id}`);
                formData.append('project_id', `${data.project_id}`);
                chunk.forEach((image, index) => {
                    formData.append(`media[${index}][image]`, image);
                    formData.append(`media[${index}][media_type]`, "1");
                })

                const response = await apiCall({
                    method: 'POST',
                    url: `/project/user/upload-image/`,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    data: formData
                })
                if (response) {


                    uploadedFilesCount += chunk.length;
                    store.dispatch(updateTotalProgress({
                        folderId: data.id, folderName: data.folder_name,
                        totalProgress: (uploadedFilesCount / files.length) * 100
                    }));
                }
            }
            // console.log(response);
            return { folder_id: data.id, project_id: data.project_id };

        } catch (error: any) {
            if (error && error.response && error.response.data) {

                showErrorToast(error.response.data.message)
                return rejectWithValue({ folder_id: data.id, project_id: data.project_id });
            }
            else {
                showErrorToast("Something went wrong. please try again later.");
                return rejectWithValue({ folder_id: data.id, project_id: data.project_id })
            }
        }
    });

// send 2 api parallel mode 
// export const updateFolderAPI = createAsyncThunk(
//     'album/updateFolderAPI',
//     async (data: { id: number, project_id: number, folder_name: string, folderImages: File[] }, { rejectWithValue }) => {
//         try {
//             const files = data.folderImages;
//             console.log("images length", files.length);
//             const chunkSize = 10;
//             const fileChunks = [];

//             // Split the images into chunks of specified size
//             for (let i = 0; i < files.length; i += chunkSize) {
//                 fileChunks.push(files.slice(i, i + chunkSize));
//             }

//             let uploadedFilesCount = 0;
//             store.dispatch(updateTotalProgress({
//                 folderId: data.id,
//                 folderName: data.folder_name,
//                 totalProgress: 1,
//             }));

//             // Initialize the active requests counter and promises array
//             let activeRequests = 0;
//             const maxRequests = 2; // Limit the number of concurrent requests

//             // Create a function to handle uploads
//             const uploadChunk = async (chunk: any) => {
//                 const formData = new FormData();
//                 formData.append('folder_name', data.folder_name);
//                 formData.append('folder_id', `${data.id}`);
//                 formData.append('project_id', `${data.project_id}`);
//                 chunk.forEach((image: any, index: any) => {
//                     formData.append(`media[${index}][image]`, image);
//                     formData.append(`media[${index}][media_type]`, "1");
//                 });

//                 const response = await apiCall({
//                     method: 'POST',
//                     url: `/project/user/upload-image/`,
//                     headers: {
//                         "Content-Type": "multipart/form-data",
//                     },
//                     data: formData,
//                 });

//                 // Update progress after a successful response
//                 uploadedFilesCount += chunk.length;
//                 store.dispatch(updateTotalProgress({
//                     folderId: data.id,
//                     folderName: data.folder_name,
//                     totalProgress: (uploadedFilesCount / files.length) * 100,
//                 }));

//                 return response; // Return response for further handling if necessary
//             };

//             // Loop through the chunks and manage active requests
//             const promises = fileChunks.map((chunk) => {
//                 return new Promise(async (resolve) => {
//                     // Wait until we have space for another request
//                     while (activeRequests >= maxRequests) {
//                         await new Promise((r) => setTimeout(r, 100)); // Wait a bit before retrying
//                     }

//                     // Increment the active requests counter
//                     activeRequests++;

//                     try {
//                         const result = await uploadChunk(chunk);
//                         resolve(result); // Resolve the promise when upload completes
//                     } catch (error) {
//                         // Handle errors here
//                         showErrorToast("An error occurred during upload.");
//                         resolve(null); // Resolve with null to not block the next request
//                     } finally {
//                         // Decrement the active requests counter
//                         activeRequests--;
//                     }
//                 });
//             });

//             // Wait for all upload promises to finish
//             await Promise.all(promises);

//             return { folder_id: data.id, project_id: data.project_id };
//         } catch (error) {
//             return rejectWithValue({ folder_id: data.id, project_id: data.project_id });
//         }
//     }
// );

export const updateFolderNameAPI = createAsyncThunk(
    'album/updateFolderNameAPI',
    async (data: { id: number, project_id: number, folder_name: string }, { rejectWithValue }) => {
        try {



            const response = await apiCall({
                method: 'PUT',
                url: `/project/update-folder/`,

                data: {
                    folder_id: data.id,
                    folder_name: data.folder_name,
                    upgrade_type: "name"
                }
            })

            return { folder_id: data.id, project_id: data.project_id };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
export const getSingleFolderAPI = createAsyncThunk(
    'album/getSingleFolderAPI',
    async (data: { folder_id: number, page: number, page_size: number }, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'GET',
                url: `/project/user/folder-detail/?folder_id=${data.folder_id}&media_type=1&page=${data.page}&page_size=${data.page_size}`,
            })
            let folder = response.data["folder_data"]
            folder.total_images = response.data.count;
            folder.images = response.data.results;
            console.log("page number: ", data.page);
            // console.log(folder)
            return { folder: folder, page: data.page };
        } catch (error: any) {
            if (error && error.response)
                return rejectWithValue(error.response.data);
            else
                return rejectWithValue({ message: "something went wrong." })
        }
    });
export const lockFolderAPI = createAsyncThunk(
    'album/lockFolderAPI',
    async (data: { project_id: number, folder_id: number, reason?: number, lock_type: string, custom_reason?: string }, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'POST',
                url: `/project/user/lock-folder/`,
                data
            })
            // console.log(response);
            return { folder_id: data.folder_id, project_id: data.project_id };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
export const unlockFolderAPI = createAsyncThunk(
    'album/unlockFolderAPI',
    async (data: { project_id: number, folder_id: number, reason?: number, lock_type: string }, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'POST',
                url: `/project/user/lock-folder/`,
                data
            })
            // console.log(response);
            return { folder_id: data.folder_id, project_id: data.project_id };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
export const lockMultipleFoldersAPI = createAsyncThunk(
    'album/lockMultipleFoldersAPI',
    async (data: { folders: number[], project_id: number, reason?: number, custom_reason?: string }, { rejectWithValue }) => {
        try {
            data.folders.forEach(async (folder) => {
                let reqData: { folder_id: number, lock_type: string, project_id: number, reason?: number, custom_reason?: string } = {
                    folder_id: folder,
                    project_id: data.project_id,
                    lock_type: "lock",

                };
                if (data.reason) {
                    reqData.reason = data.reason;
                } else {
                    reqData.custom_reason = data.custom_reason;
                }
                const response = await apiCall({
                    method: 'POST',
                    url: `/project/user/lock-folder/`,
                    data: reqData
                })
            })

            return true;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
export const deleteFolderImagesAPI = createAsyncThunk(
    'album/deleteFolderImagesAPI',
    async (data: { media_ids: number[], folder_id: number, project_id: number }, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'DELETE',
                url: `/project/user/delete-media/`,
                data: data
            })
            // console.log(response);
            return { folder_id: data.folder_id, project_id: data.project_id };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });

export const deleteFolderAPI = createAsyncThunk(
    'album/deleteFolderAPI',
    async (data: { folder_id: number, project_id: number }, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'DELETE',
                url: `/project/user/upload-image/?folder_id=${data.folder_id}`,
                data
            })
            // console.log(response);
            return { folder_id: data.folder_id, project_id: data.project_id };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
export const hideFolderAPI = createAsyncThunk(
    'album/hideFolderAPI',
    async (data: { project_id: number, folder_id: number, lock_type: string }, { rejectWithValue }) => {
        try {
            const response = await apiCall({
                method: 'POST',
                url: `/project/user/lock-folder/`,
                data
            })
            // console.log(response);
            return { folder_id: data.folder_id, project_id: data.project_id };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });


export const toggleWatermarkAPI = createAsyncThunk(
    'album/toggleWatermarkAPI',
    async (data: { folder_id: number, add_watermark: string, project_id: number }, { rejectWithValue }) => {
        try {
            console.log(data)
            const response = await apiCall({
                method: 'POST',
                url: `/project/user/add-watermark/`,
                data: { folder_id: data.folder_id, activate_watermark: data.add_watermark }
            })
            // console.log(response);
            return { folder_id: data.folder_id, project_id: data.project_id };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    });
