
import { createAsyncThunk } from '@reduxjs/toolkit';
import apiCall from '../AuthorizedApi';
import store from '../../Store';
import { setIsPlanChangedFlag } from '../../Slice/Auth/AuthSlice';


export const createOrderAPI = createAsyncThunk('payment/createOrderAPI', async (data: { storage_plan_id: number, duration: number, settlement_for: string }, { rejectWithValue }) => {
    try {
        const res = await apiCall({
            method: 'POST',
            url: `/plans/payment/`,
            data
        })

        // console.log(folder)
        return res.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const completePaymentAPI = createAsyncThunk('payment/completePaymentAPI', async (data: any, { rejectWithValue }) => {
    try {
        const res = await apiCall({
            method: 'POST',
            url: `/plans/transaction/`,
            data
        })

        // console.log(folder)
        if (res) {
            store.dispatch(setIsPlanChangedFlag(true));
        }
        return res.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

