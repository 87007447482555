import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';



import { showErrorToast } from '../../../components/atoms/Utlis/Toast';
import { SubscriptionType } from '../../../Data/subscription.dto';
import { createQuotationAPI } from '../../ApiCalls/Dashboard/QuotationAPI';
import { QuotationState } from '../../Slice/Dashboard/QuotationSlice';
export const QuotationReducer = (builder: ActionReducerMapBuilder<QuotationState>) => {
    builder.addCase(createQuotationAPI.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.error = {};
    })
        .addCase(createQuotationAPI.fulfilled, (state, action: PayloadAction<SubscriptionType[]>) => {
            state.loading = false;
            state.isError = false;


        })
        .addCase(createQuotationAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;

            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        })
};
