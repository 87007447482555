import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import PlusSignIconPNG from '../../../assets/Icons/addIcon.png'
const AllQuotationPage = () => {
    const navigate = useNavigate();
    return (
        <Container>

            <HeaderContainer>
                <HeaderText>Quotations</HeaderText>
            </HeaderContainer>
            <ButtonContainer>
                <AddAlbumButton
                    onClick={() => {
                        navigate('/dashboard/quotation/singleQuotation')
                    }}
                >
                    <PlusSignContainer>
                        <PlusSignIcon src={PlusSignIconPNG}></PlusSignIcon>
                    </PlusSignContainer>
                    <ButtonText>ADD</ButtonText>
                </AddAlbumButton>
            </ButtonContainer>
        </Container>
    )
}

export default AllQuotationPage


const Container = styled.div`
width:100%;
height:100%;

`;

const HeaderContainer = styled.div`
width:100%;
`;

const HeaderText = styled.p`

  height: 17px;
  font-family: 'Urbanist', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;

`;


const ButtonContainer = styled.div`


`;

const BodyContainer = styled.div``;


const AddAlbumButton = styled.button`
  border: 1px solid #a720b9;
  width: 80px;
  height: 36px;
  margin-right: 52px;
  border: 1px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid #a720b9;
  cursor: pointer;
  float:right;
`
const PlusSignContainer = styled.div``
const PlusSignIcon = styled.img`
  height: 21px;
  width: 21px;
`

const ButtonText = styled.div`
  width: 29px;
  height: 17px;
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
  color: #a720b9;
`