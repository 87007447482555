import React, { useState } from 'react'
import styled from 'styled-components'

import BackButtonIconPng from '../../../assets/Icons/SingleAlbum/back.png'
import { useNavigate } from 'react-router-dom'
import CustomerDataForm from '../../molecules/Dashboard/Quotation/CustomerDataForm'
import {
    QuotationAlbumType,
    QuotationCustomer,
    QuotationEventType,
    QuotationGiftsType,
    QuotationPackageDetailsType,
} from '../../../Data/quotation.dto'
import QuotationEvents from '../../molecules/Dashboard/Quotation/QuotationEvents'
import QuotationAlbumDetails from '../../molecules/Dashboard/Quotation/QuotationAlbumDetails'
import SpecialGifts from '../../molecules/Dashboard/Quotation/SpecialGifts'
import QuotationPackageDetails from '../../molecules/Dashboard/Quotation/QuotationPackageDetails'
import { createQuotationAPI } from '../../../Redux/ApiCalls/Dashboard/QuotationAPI'
import { useAppDispatch } from '../../../Redux/Hooks'

const SingleQuotationPage = () => {
    const [currentScreen, setCurrentScreen] = useState(1)
    const [customerData, setCustomerData] = useState<QuotationCustomer>()
    const [quotationEvents, setQuotationEvents] = useState<QuotationEventType[]>([])
    const [quotationAlbum, setQuotationAlbum] = useState<QuotationAlbumType>()
    const [quotationGifts, setQuotationGifts] = useState<QuotationGiftsType[]>([])
    const [packageDetails, setPackageDetails] = useState<QuotationPackageDetailsType>();
    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    const handleCustomerData = (data: QuotationCustomer) => {
        setCustomerData(data)
    }

    const handleQuotationEvents = (data: QuotationEventType[]) => {
        setQuotationEvents(data)
    }

    const handlePreviousScreen = () => {
        setCurrentScreen((pre) => {
            if (pre && pre > 1) return pre - 1
            else return pre
        })
    }
    const handleNextScreen = () => {
        setCurrentScreen((pre) => {
            return pre + 1;
        })
    }

    const handleQuotationGifts = (data: QuotationGiftsType[]) => {
        setQuotationGifts(data);
    }

    const handleQuotationAlbum = (data: QuotationAlbumType) => {
        console.log(data);
        setQuotationAlbum(data)
    }
    const handleSubmitQuotation = async (data: any) => {
        try {
            const quotationData = {
                quotation: {
                    customer_name: customerData?.name,
                    mobile_number: customerData?.phone,
                    reference: customerData?.reference,
                    place: customerData?.place,
                    address: customerData?.address,
                    details: packageDetails?.product_details
                },
                event_information: quotationEvents.map(event => {
                    event.function_date = convertDate(event.function_date);
                    return event;
                }),
                special_gift_information: quotationGifts,
                album_details: quotationAlbum,
                package: {
                    total_price: data.package_price || packageDetails?.package_price,
                    discount: data.discount || packageDetails?.discount,
                    total: data.total || packageDetails?.total,
                    optional: data.optional_data || packageDetails?.optional_data,
                    terms: data.terms_and_condition || packageDetails?.terms_and_condition

                }

            }
            console.log(quotationData)
            const result = await dispatch(createQuotationAPI(quotationData)).unwrap();
            console.log(result);
        } catch (error) {
            console.log(error);
        }
    }
    const handlePackageDetails = (data: QuotationPackageDetailsType) => {

        setPackageDetails(data);
        handleSubmitQuotation(data);
    }
    function convertDate(dateStr: string) {
        // Split the input string into day, month, and year parts
        const [day, month, year] = dateStr.split('/');
        console.log(day, month, year, `${year}-${month}-${day}`)
        // Return the date in the new format YYYY/MM/DD
        return `${year}-${month}-${day}`;
    }


    return (
        <Container>
            <HeaderContainer>
                <BackButtonContainer onClick={() => navigate(-1)}>
                    <BackButtonIcon src={BackButtonIconPng} />
                    <BackButtonText>Back</BackButtonText>
                </BackButtonContainer>
                <HeaderTextContainer>
                    <HeaderText>Quotation Form</HeaderText>
                </HeaderTextContainer>
                <BodyContainer>
                    {currentScreen == 1 ? (
                        <CustomerDataForm
                            customerData={customerData}
                            handleCustomerData={(data: QuotationCustomer) => {
                                handleCustomerData(data)
                                setCurrentScreen((pre) => pre + 1)
                            }}
                            handlePreviousScreen={handlePreviousScreen}
                        />
                    ) : currentScreen == 2 ? (
                        <QuotationEvents
                            handleQuotationEvents={(data) => {
                                handleQuotationEvents(data)
                                setCurrentScreen((pre) => pre + 1)
                            }}
                            quotationEvents={quotationEvents}
                            handlePreviousScreen={handlePreviousScreen}
                        />
                    ) : currentScreen == 3 ? (
                        <QuotationAlbumDetails
                            handleQuotationAlbum={(data) => {
                                handleQuotationAlbum(data)
                                setCurrentScreen((pre) => pre + 1)
                            }}
                            handleNextScreen={handleNextScreen}
                            quotationAlbum={quotationAlbum}
                            handlePreviousScreen={handlePreviousScreen}
                        />
                    ) :
                        currentScreen === 4 ? (
                            <SpecialGifts
                                quotationGifts={quotationGifts}
                                handleNextScreen={handleNextScreen}
                                handleQuotationGifts={handleQuotationGifts}
                                handlePreviousScreen={handlePreviousScreen}

                            />
                        )
                            :
                            currentScreen === 5 ? (
                                <QuotationPackageDetails
                                    handlePreviousScreen={handlePreviousScreen}
                                    packageDetails={packageDetails}
                                    handleNextScreen={handleNextScreen}
                                    handleSubmit={(data) => handlePackageDetails(data)}
                                />
                            )
                                : null}
                </BodyContainer>
            </HeaderContainer>
        </Container>
    )
}

export default SingleQuotationPage

const Container = styled.div``

const HeaderContainer = styled.div``

const HeaderTextContainer = styled.div`
  width: 100%;
`
const HeaderText = styled.p`
  font-family: Urbanist, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 22.8px;
  text-align: center;
  color: #171717;
  margin: 0px;
  margin-left: 11px;
`

const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 10%;
  margin-left: 30px;
  align-items: center;
`
const BackButtonIcon = styled.img`
  width: 19.6px;
  height: 16.8px;
  color: #171717;
  cursor: pointer;
`

const BackButtonText = styled.p`
  font-family: Urbanist, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22.8px;
  text-align: left;
  color: #171717;
  margin: 0px;
  margin-left: 11px;
`

const BodyContainer = styled.div`
  height: 806px;
  background-color: hsla(0, 0%, 100%, 0.8);
  margin: 30px;
  padding: 30px;
  border-radius: 15px;
  border: 1px solid gray;
`
