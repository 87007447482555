import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { MemberState } from '../../Slice/Dashboard/MemberSlice';
import { subscriptionsPlansAPI } from '../../ApiCalls/Dashboard/SubscriptionAPI';
import { ExtraState } from '../../Slice/Dashboard/ExtraSlice';
import { getAllNotification, getAllUnseenNotificationAPI, seenNotification } from '../../ApiCalls/Dashboard/NotificationAPI';
import { Notification } from '../../../Data/user.dto';
export const NotificationReducer = (builder: ActionReducerMapBuilder<ExtraState>) => {
    builder.addCase(getAllUnseenNotificationAPI.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.error = {};
    })
        .addCase(getAllUnseenNotificationAPI.fulfilled, (state, action: PayloadAction<Notification[]>) => {
            state.loading = false;
            state.isNotificationUpdated = false;
            state.notifications = [];
            state.allUnseenNotifications = action.payload;
            state.isError = false;

            action.payload.forEach(notification => {
                if (!notification.is_seen) {
                    state.notifications.push(notification);
                }

            });
            state.totalNotifications = state.notifications.length;
        })
        .addCase(getAllUnseenNotificationAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;
            state.isNotificationUpdated = false;

        }).addCase(seenNotification.pending, (state) => {
            state.loading = true;
            state.isError = false;
            state.error = {};
        })
        .addCase(seenNotification.fulfilled, (state, action: PayloadAction<Notification[]>) => {
            state.loading = false;
            state.isError = false;
            state.isNotificationUpdated = true;
            // state.notifications = action.payload;
        })
        .addCase(seenNotification.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;

            // console.log(action.payload);
            state.error = action.payload;
        }).addCase(getAllNotification.pending, (state) => {
            state.loading = true;
            state.isError = false;
            state.error = {};
        })
        .addCase(getAllNotification.fulfilled, (state, action: PayloadAction<{
            data: Notification[],
            total_items: number,
            current_page: number,
        }>) => {
            state.loading = false;
            state.isError = false;
            state.isNotificationUpdated = false;

            state.allNotifications = action.payload.data
            state.currentNotificationPage = action.payload.current_page;

            state.totalAllNotifications = action.payload.total_items;
            state.allUnseenNotifications = action.payload.data.filter(notification => !notification.is_seen)
            // state.notifications = action.payload;
        })
        .addCase(getAllNotification.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;

            // console.log(action.payload);
            state.error = action.payload;
        })
};

// 
// "id": "18",
// "country_code": "+91",
// "phone_number": "8849827290",
// "profile_image": "https://res.cloudinary.com/dcuwbzynm/image/upload/v1718603255/gthlwzqyka7kcbsczocy.png",
// "name": "Pinkesh Patel",
// "job_type": "Camera Man",
// "user_id": null