import React from 'react';
import styled from 'styled-components';
import AllNotificationPNG from '../../../../assets/Icons/allNotiifcation.png';

const Container = styled.div`
  display: flex;
  width: 90%;
  margin-left: 30px;
  align-items: center;
  margin-top: 10px;
`;

const DataContainer = styled.div``;

const IconContainer = styled.div`
  width: 47px;
  height: 47px;
  background: #a720b926;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const Icon = styled.img`
  width: 25.16px;
  height: 19.68px;
`;

const NotificationTitle = styled.p`
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  margin: 0;
`;

const NotificationBody = styled.p`
  font-family: Urbanist;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  margin: 0;
`;

interface NotificationItemProps {
    title: string;
    body: string;
    seen: boolean;
    time: string;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ title, body, seen, time }) => {
    const timeAgo = (date: Date): string => {
        const now = new Date();
        const secondsPast = Math.floor((now.getTime() - date.getTime()) / 1000);

        if (secondsPast < 60) {
            return `${secondsPast}s ago`;
        }
        if (secondsPast < 3600) {
            return `${Math.floor(secondsPast / 60)}m ago`;
        }
        if (secondsPast < 86400) {
            return `${Math.floor(secondsPast / 3600)}h ago`;
        }
        if (secondsPast < 2592000) {
            return `${Math.floor(secondsPast / 86400)}d ago`;
        }
        if (secondsPast < 31536000) {
            return `${Math.floor(secondsPast / 2592000)}M ago`;
        }
        return `${Math.floor(secondsPast / 31536000)}y ago`;
    };

    return (
        <Container>
            <IconContainer>
                <Icon src={AllNotificationPNG} />
            </IconContainer>
            <DataContainer>
                <NotificationTitle>
                    {title} ({timeAgo(new Date(time))})
                </NotificationTitle>
                <NotificationBody>
                    {body} {seen ? 'Seen' : 'Unseen'}
                </NotificationBody>
            </DataContainer>
        </Container>
    );
};

export default NotificationItem;
