import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { QuotationAlbumType, QuotationGiftsType } from '../../../../Data/quotation.dto'
import Errortext from '../../../atoms/Utlis/Errortext'

interface QuotationGiftsPropsType {
    quotationGifts: QuotationGiftsType[];
    handleNextScreen: () => void;
    handlePreviousScreen: () => void;
    handleQuotationGifts: (data: QuotationGiftsType[]) => void;
}


const SpecialGifts: React.FC<QuotationGiftsPropsType> = ({ quotationGifts, handleNextScreen, handlePreviousScreen, handleQuotationGifts }) => {
    const [gifts, setGifts] = useState<QuotationGiftsType[]>([])
    const [showError, setShowError] = useState(false);
    const [giftName, setGiftName] = useState<string>()
    const [giftQuantity, setGiftQuantity] = useState<number>()
    const [isUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        if (isDataUpdated()) {
            setIsUpdated(true);
        } else {
            setIsUpdated(false);
        }
    }, [gifts])
    useEffect(() => {
        setGifts(quotationGifts)
    }
        , [])
    const AddNewGift = () => {
        if (giftName && giftQuantity && giftQuantity > 0) {
            let newProduct = {
                product: giftName,
                quantity: giftQuantity
            }
            setGifts([...gifts, newProduct])

        } else {
            setShowError(true);
        }
    }
    const isDataUpdated = () => {
        // console.log("value===>", JSON.stringify(gifts), JSON.stringify(quotationGifts))
        // console.log("comparision===>", JSON.stringify(gifts) !== JSON.stringify(quotationGifts))
        return JSON.stringify(gifts) !== JSON.stringify(quotationGifts);
    };
    const onChangeCreatedGiftData = (
        field: string, // either "product" or "quantity"
        value: string | number, // depending on the type, it could be string (for product) or number (for quantity)
        index: number
    ) => {
        setGifts((prevGifts) =>
            prevGifts.map((gift, i) =>
                i === index ? { ...gift, [field]: value } : gift
            )
        );
    };
    const onChangeData = (key: string, value: any) => {
        if (key === 'name' && value.toString().length <= 30) {
            setGiftName(value.toString());
        } else if (key === 'quantity') {
            if (value) {
                if (value.toString().length <= 5) {
                    setGiftQuantity(value);
                }
            } else {
                setGiftQuantity(0)
            }
        }
    }
    const onRemoveData = (data: QuotationGiftsType, index: number) => {
        if (data.id) {

        } else {
            setGifts(pre => pre.filter((p, i) => index !== i))
        }
    }


    const validData = (key: string) => {
        switch (key) {
            case "name":
                return giftName && giftName.length > 0;
            case "quantity":
                return giftQuantity !== undefined && giftQuantity > 0;
            default:
                return true;
        }
    }
    const onSubmit = () => {
        if (isUpdated) {
            handleQuotationGifts(gifts);
            handleNextScreen();
            setIsUpdated(false);
        } else {
            handleNextScreen();
        }
    }
    return (
        <Container>
            <HeaderContainer>
                <HeaderText>
                    Special Gifts
                </HeaderText>
            </HeaderContainer>
            <BodyContainer>
                <LabelContainer>
                    <GiftNameLabel >Gift Name</GiftNameLabel>
                    <GiftNameLabel>Gift Quantity</GiftNameLabel>
                </LabelContainer>
                <GiftInputContainer>
                    <GiftNameContainer>

                        <GiftInput placeholder='Special Gift Name' type='text' value={giftName} onChange={(e) => onChangeData("name", e.target.value)} />
                        <Errortext show={showError && !validData("name")} message='Please provide valid name' />
                    </GiftNameContainer>

                    <GiftQuantityContainer>

                        <GiftInput type='number' value={giftQuantity} onChange={(e) => onChangeData("quantity", parseInt(e.target.value))} />
                        <Errortext show={showError && !validData("quantity")} message='Please provide valid quantity' />
                    </GiftQuantityContainer>
                    <AddButtonContainer>
                        <AddButton onClick={() => AddNewGift()}>ADD</AddButton>
                    </AddButtonContainer>
                </GiftInputContainer>
                <GiftListContainer>

                    {gifts.length > 0 &&
                        <GiftListContainerHeader>Added Gifts</GiftListContainerHeader>
                    }
                    {gifts && gifts.map((gift, index) => (
                        <GiftsItemContainer>
                            <GiftNameContainer>

                                <GiftInput onChange={(e) => onChangeCreatedGiftData("product", e.target.value, index)} type='text' value={gift.product} />
                            </GiftNameContainer>

                            <GiftQuantityContainer>

                                <GiftInput type='number' value={gift.quantity} onChange={(e) => onChangeCreatedGiftData("quantity", parseInt(e.target.value), index)} />
                            </GiftQuantityContainer>
                            <AddButtonContainer>
                                <AddButton onClick={() => onRemoveData(gift, index)}>Remove</AddButton>
                            </AddButtonContainer>
                        </GiftsItemContainer>
                    ))}
                </GiftListContainer>
            </BodyContainer>
            <ButtonContainer>
                <PreviousButton onClick={() => handlePreviousScreen()}>Previous</PreviousButton>
                <NextButton onClick={() => onSubmit()}>{isUpdated ? 'Submit' : 'Next'}</NextButton>
            </ButtonContainer>
        </Container>
    )
}

export default SpecialGifts



const Container = styled.div``;

const HeaderText = styled.div`

font-family: Urbanist,sans-serif;
font-size: 24px;
font-weight: 600;
line-height: 22.8px;
text-align: center;
color: #171717;
margin:0px;
margin-left:11px;
`;

const HeaderContainer = styled.div`
width:90%;

`;

const BodyContainer = styled.div`
height:580px;
display: flex;
margin-top: 40px;
flex-direction: column;
align-items: center;
`;
const GiftInputContainer = styled.div`
display: flex;
align-items: baseline;
width: 100%;
justify-content: center;
margin-top:40px;
`;
const GiftNameContainer = styled.div`
width:45%;
`;

const GiftInput = styled.input`
margin-top: 7px;
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;

  border: 1px solid gray;
  border-radius:15px;
  padding:7px;
  width:90%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }

`;

const GiftQuantityContainer = styled.div`
width:45%;
`;

const GiftQuantityInput = styled.input``;


const LabelContainer = styled.div`
width: 90%;
display:flex;
text-align:center;

`;

const GiftNameLabel = styled.div`
width: 45%;
font-family: Montserrat,sans-serif;
font-size: 18px;
font-weight: 600;
line-height: 22.8px;
text-align: center;
color: #171717;
`;

const GiftQuantityLabel = styled.div`
width:45;
`;

const AddButtonContainer = styled.div`
width:10%;
`;

const AddButton = styled.button`
cursor:pointer;
    padding: 9px 5px;
    background-color: transparent;
    border: 1px solid #AE2AB1;
    border-radius: 10px;
    width: 62px;
    color:#AE2AB1;

`;

const GiftListContainer = styled.div`
margin-top:40px;
width: 100%;
max-height:397px;
overflow-y: auto;
`;

const GiftListContainerHeader = styled.div`
width:90%;
font-family: Urbanist,sans-serif;
font-size: 24px;
font-weight: 600;
line-height: 22.8px;
text-align: center;
color: #171717;
margin:0px;

`;

const GiftsItemContainer = styled.div`
display: flex;
align-items: baseline;
width: 100%;
justify-content: center;
margin-top:20px;
`


const ButtonContainer = styled.div`
width: 100%;
display:flex;
align-items: center;
justify-content: center;
font-family: 'Montserrat', sans-serif;
  
  line-height: 17.07px;
`;

const PreviousButton = styled.button`
width:120px;
padding:10px;
background-color:#c5c5c5;
color:white;
font-size: 18px;
  font-weight: 600;
  margin-right:30px;
border:none;
border-radius:15px;
cursor:pointer;
&:hover{
background-color:#adadad;
}

`;


const NextButton = styled.button`
width:120px;
padding:10px;
background-color:#AE2AB1;
color:white;
font-size: 18px;
  font-weight: 600;
  margin-right:30px;
border:none;
border-radius:15px;
cursor:pointer;
&:hover{
background-color:#c433c7;
}
`;
