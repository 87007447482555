import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd';
// import 'antd/dist/reset.css'; // Import Ant Design CSS
import dayjs from 'dayjs';


const StyledDatePicker = styled(DatePicker)`
   & .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #AE2AB1 !important;
    color: white !important;
  }
  & .ant-picker-cell-today .ant-picker-cell-inner {
    border-color: #AE2AB1 !important;
  }
    
`;
interface DatePickerComponentPropsType {
    onChange: (value: any) => void;
    dateValue?: string
}
const DatePickerComponent: React.FC<DatePickerComponentPropsType> = ({ onChange, dateValue }) => {

    const formatDateString = (dateString: string | undefined) => {
        if (dateString) {
            // Parse the date using dayjs with a custom format
            const parsedDate = dayjs(dateString, "DD/MM/YYYY", true); // strict parsing
            console.log("formatted date", dateString, parsedDate);
            return parsedDate.isValid() ? parsedDate : null;
        }
        return null;
    };
    return (
        <StyledDatePicker
            onChange={(date: any) => {
                if (date) {

                    console.log("change date", date.format("DD/MM/YYYY"));
                    onChange(date.format("DD/MM/YYYY").toString())
                }
            }}
            format="DD/MM/YYYY"
            value={formatDateString(dateValue)}
            placeholder="Select Date"
        />
        // <></>
    );
};

export default DatePickerComponent;
