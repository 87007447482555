import React, { useState } from 'react'
import styled from 'styled-components'
import PreviewImagePNG from '../../../../assets/images/dummyImage.jpg'


const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
position: fixed;
top: 0;
left: 0;
z-index: 1000;
background-color: rgba(0, 0, 0, 0.8);
`;
const Popup = styled.div`
max-width:900px;
max-height: 900px;
background-color: transparent;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-radius:32px;
`;
const Image = styled.img`
width:100%;
height:100%;
`;
const HeaderContainer = styled.div`
width: 100;
`;

const CloseButton = styled.p`
float:right;
position: absolute;
top:40px;
right:30px;
font-size:25px;
font-weight:bold;
color:#A720B9;
background:white;
padding:10px 15px;
border:1px solid black;
border-radius:50%;
border:none;
`;


interface SingleImageModalProps {
    imageUrl: string;
    thumbnailUrl?: string;
    onClose: () => void;

}
const SingleImageModal: React.FC<SingleImageModalProps> = ({ thumbnailUrl, imageUrl, onClose }) => {
    const [url, setUrl] = useState(thumbnailUrl);
    const [load, setLoad] = useState(false);

    return (
        <Container>
            <Popup>
                <CloseButton onClick={(e) => {
                    console.log("called....")
                    e.stopPropagation();
                    onClose()
                }}>X</CloseButton>
                <Image src={!load ? thumbnailUrl ? thumbnailUrl : PreviewImagePNG : imageUrl} onLoad={() => setLoad(true)} />
            </Popup>
        </Container>
    )
}

export default SingleImageModal