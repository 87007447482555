import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks'
import styled from 'styled-components'
import NotificationItem from '../../atoms/Dashboard/Notification/NotificationItem';
import { Notification } from '../../../Data/user.dto'
import { getAllNotification } from '../../../Redux/ApiCalls/Dashboard/NotificationAPI';
import UnderLine from '../../atoms/Login/UnderLine';

const Container = styled.div`
width:100%;
height:973px;
overflow-y: auto;
`;

const HeaderContainer = styled.div`
width:100%;
`;

const HeaderText = styled.p`
font-family: Urbanist;
font-size: 28px;
font-style: italic;
font-weight: 700;
line-height: 33.6px;
text-align: center;
`;
const BodyContainer = styled.div`
margin-top:30px;

`;
const AllNotificationPage: React.FC = () => {
    const { allNotifications, isNotificationUpdated } = useAppSelector(state => state.extra)
    const [notifications, setNotifications] = useState<Notification[]>([])
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isNotificationUpdated || allNotifications.length === 0) {
            dispatch(getAllNotification());
        } else {

            setNotifications(allNotifications);
        }
    }, [allNotifications, isNotificationUpdated])


    return (
        <Container>
            <HeaderContainer>
                <HeaderText>All Notifications</HeaderText>
            </HeaderContainer>
            <UnderLine isPercent={true} width={96} />
            <BodyContainer>

                {allNotifications && allNotifications.map(noti =>
                    <NotificationItem title={noti.title} body={noti.message} seen={noti.is_seen} time={noti.created_at} />
                )}
            </BodyContainer>
        </Container>
    )
}

export default AllNotificationPage

