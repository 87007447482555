import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import UnderLine from '../../Login/UnderLine'
import SubmitButton from '../../Login/SubmitButton'
import Errortext from '../../Utlis/Errortext'
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks'
import { sendAccountInfoAPI } from '../../../../Redux/ApiCalls/Dashboard/PackageAPI'
import { verifyEmailLinkAPI } from '../../../../Redux/ApiCalls/Auth/user'
const breakpoints = {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
}
interface FormProps {
    account_number?: string
    ifsc_code?: string
    beneficiary_name?: string
    tnc_accepted?: boolean
    user_address?: {
        address_line_1?: string
        address_line_2?: string
        city?: string
        state?: string
        zip_code?: string
        country?: string
    }
    onClose: () => void
}

const AccountInformationForm: React.FC<FormProps> = ({
    account_number,
    ifsc_code,
    beneficiary_name,
    user_address,
    onClose,
}) => {
    const [selectedCountry, setSelectedCountry] = React.useState<string>(
        user_address?.country || 'India'
    )
    const [selectedRegion, setSelectedRegion] = React.useState<string>(
        user_address?.state || '',
    )
    const [accName, setAccName] = useState<string>('')
    const [accNo, setAccNo] = useState<string>('')
    const [accIFSC, setAccIFSC] = useState<string>('')
    const [add1, setAdd1] = useState<string>('')
    const [add2, setAdd2] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [zipcode, setZipCode] = useState<number>()
    const [TnCChecked, setTnCChecked] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const { user } = useAppSelector(state => state.auth)
    useEffect(() => {
        if (account_number) {
            setAccNo(account_number)
        } if (ifsc_code) {
            setAccIFSC(ifsc_code)
        } if (beneficiary_name) {
            setAccName(beneficiary_name)
        }


    }, [])
    const onChangeData = (name: string, value: string) => {
        if (name === 'acc_name') {
            setAccName(value)
        } else if (name === 'acc_no' && value.length <= 18 && value.length >= 0) {
            setAccNo(value)
        } else if (name === 'acc_ifsc') {
            setAccIFSC(value)
        } else if (name === 'add1') {
            setAdd1(value)
        } else if (name === 'add2') {
            setAdd2(value)
        } else if (name === 'city') {
            setCity(value)
        } else if (name === 'zip' && value.length < 8) {
            setZipCode(parseInt(value))
        }
    }
    const handleValidation = (name: string, value: string) => {
        if (name === 'acc_name') {
            return accName.length > 0
        } else if (name === 'acc_no') {
            return value.length > 0
        } else if (name === 'acc_ifsc') {
            return value.length > 0
        } else if (name === 'zip') {
            return value.length > 0
        } else if (name === 'city') {
            return value.length > 0
        } else if (name === 'add1') {
            return value.length > 0
        } else if (name === 'add2') {
            return value.length > 0
        } else if (name === 'state') {
            return value.length > 0
        } else if (name === 'country') {
            return value.length > 0
        } else true;
    }
    const handleVerifyEmail = (email: string) => {
        console.log(email)
        dispatch(verifyEmailLinkAPI({ email }))
    }

    const handleSubmit = () => {
        if (
            handleValidation('acc_name', accName) &&
            handleValidation('acc_no', accNo.toString()) &&
            handleValidation('acc_ifsc', accIFSC) &&
            handleValidation('add1', add1) &&
            handleValidation('add2', add2) &&
            handleValidation('city', city) &&
            zipcode && handleValidation('zip', zipcode.toString()) &&
            handleValidation('state', selectedRegion) &&
            handleValidation('country', selectedCountry)


        ) {
            dispatch(
                sendAccountInfoAPI({
                    account_number: accNo,
                    ifsc_code: accIFSC,
                    beneficiary_name: accName,
                    tnc_accepted: TnCChecked,
                    address_line_1: add1,
                    address_line_2: add2,
                    city,
                    state: selectedRegion,
                    zip_code: zipcode,
                    country: selectedCountry
                }),
            )
            onClose();
            // account_number": "1234567890",
            // "ifsc_code": "IFSC0001234",
            //     "beneficiary_name": "John Doe",
            //         "tnc_accepted": true,
            //             "address_line_1": "123 Main St",
            //                 "address_line_2": "Suite 100",
            //                     "city": "Metropolis",
            //                         "state": "Gujarat",
            //                             "zip_code": "123456",
            //                                 "country": "India"
        } else {
            setShowError(true)
        }
    }
    if (!user) return null;
    return (
        <Modal>
            <ModalContent>
                <CloseButton onClick={() => onClose()}>&times;</CloseButton>
                <FormContainer>
                    <FormHeader><div>Account Information</div> <VerifyButton isVerify={user.is_email_verified} onClick={() => { if (!user.is_email_verified) handleVerifyEmail(user.email) }}>{user?.is_email_verified ? `Email Verified` : 'Verify Email'}</VerifyButton></FormHeader>
                    <FormBody>
                        <FormField>
                            <InputContainer>
                                <Label htmlFor="beneficiary_name">Beneficiary Name</Label>
                                <Input
                                    type="text"
                                    id="beneficiary_name"
                                    name="beneficiary_name"
                                    value={accName}
                                    // placeholder="John Doe"
                                    onChange={(e) => onChangeData('acc_name', e.target.value)}
                                />
                            </InputContainer>
                            <UnderLine width={100} isPercent />
                            <Errortext
                                message="Please provide valid account name"
                                show={showError && !handleValidation('acc_name', accName)}
                            />
                            <InputContainer>
                                <Label htmlFor="account_number">Account Number</Label>
                                <Input
                                    type="number"
                                    id="account_number"
                                    name="account_number"
                                    value={accNo}
                                    onChange={(e) => onChangeData('acc_no', e.target.value)}
                                    placeholder="123456789"
                                />
                            </InputContainer>
                            <UnderLine width={100} isPercent />
                            <Errortext
                                message="Please provide valid account number"
                                show={
                                    showError && !handleValidation('acc_no', accNo.toString())
                                }
                            />

                            <InputContainer>
                                <Label htmlFor="ifsc_code">IFSC Code</Label>
                                <Input
                                    type="text"
                                    id="ifsc_code"
                                    name="ifsc_code"
                                    value={accIFSC}
                                    onChange={(e) => onChangeData('acc_ifsc', e.target.value)}
                                // placeholder="ABC1234"
                                />
                            </InputContainer>
                            <UnderLine width={100} isPercent />
                            <Errortext
                                message="Please provide valid account IFSC code"
                                show={showError && !handleValidation('acc_ifsc', accIFSC)}
                            />

                            <>
                                <InputContainer>
                                    <Label htmlFor="address_line_1">Address Line 1</Label>
                                    <Input
                                        type="text"
                                        id="address_line_1"
                                        name="address_line_1"
                                        value={add1}
                                        // placeholder="123 Main St"
                                        onChange={(e) => onChangeData('add1', e.target.value)}
                                    />
                                </InputContainer>
                                <UnderLine width={100} isPercent />
                                <Errortext
                                    message="Please provide valid addressline 1"
                                    show={showError && !handleValidation('acc_no', add1)}
                                />

                                <InputContainer>
                                    <Label htmlFor="address_line_2">Address Line 2</Label>
                                    <Input
                                        type="text"
                                        id="address_line_2"
                                        name="address_line_2"
                                        value={add2}
                                        onChange={(e) => onChangeData('add2', e.target.value)}
                                    // placeholder="Apt 4B"
                                    />
                                </InputContainer>
                                <UnderLine width={100} isPercent />
                                <Errortext
                                    message="Please provide valid addressline 2"
                                    show={showError && !handleValidation('acc_no', add2)}
                                />

                                <InputContainer>
                                    <Label htmlFor="country">Country</Label>
                                    <StyledCountryDropdown
                                        value={selectedCountry || 'India'}
                                        onChange={(val) => { console.log(val); setSelectedCountry(val) }}
                                    />
                                </InputContainer>
                                <UnderLine width={100} isPercent />
                                <Errortext
                                    message="Please provide Country "
                                    show={
                                        showError && !handleValidation('country', selectedCountry)
                                    }
                                />
                                <InputContainer>
                                    <Label htmlFor="state">State/Region</Label>
                                    <StyledRegionDropdown
                                        country={selectedCountry || ''}
                                        value={selectedRegion || ''}
                                        onChange={(val) => setSelectedRegion(val)}
                                    />
                                </InputContainer>
                                <UnderLine width={100} isPercent />
                                <Errortext
                                    message="Please provide State"
                                    show={
                                        showError && !handleValidation('state', selectedRegion)
                                    }
                                />
                                <InputContainer>
                                    <Label htmlFor="city">City</Label>
                                    <Input
                                        type="text"
                                        id="city"
                                        value={city}
                                        name="city"
                                        onChange={(e) => onChangeData('city', e.target.value)}
                                    />
                                </InputContainer>

                                <UnderLine width={100} isPercent />
                                <Errortext
                                    message="Please provide City"
                                    show={showError && !handleValidation('city', city)}
                                />

                                <InputContainer>
                                    <Label htmlFor="zip_code">ZIP Code</Label>
                                    <Input
                                        type="number"
                                        id="zip_code"
                                        name="zip_code"
                                        value={zipcode}
                                        onChange={(e) => onChangeData('zip', e.target.value)}
                                    // placeholder="10001"
                                    />
                                </InputContainer>
                                <UnderLine width={100} isPercent />
                                <Errortext
                                    message="Please provide zipcode "
                                    show={
                                        showError && !(zipcode && handleValidation('zip', zipcode.toString()))
                                    }
                                />

                                <InputContainer>
                                    <CheckboxContainer>
                                        <Input
                                            type="checkbox"
                                            id="tnc_accepted"
                                            name="tnc_accepted"
                                            checked={TnCChecked}
                                            onChange={(e) => setTnCChecked((pre) => !pre)}
                                        />
                                        <Label htmlFor="tnc_accepted">
                                            I accept the terms and conditions
                                        </Label>
                                    </CheckboxContainer>
                                </InputContainer>
                            </>

                        </FormField>
                    </FormBody>
                    <SubmitButtonContainer>
                        <SubmitButton
                            text="Submit"
                            width={200}
                            onClick={() => handleSubmit()}
                            needArrow={false}
                            active={!TnCChecked}
                        />
                    </SubmitButtonContainer>
                </FormContainer>
            </ModalContent>
        </Modal>
    )
}

export default AccountInformationForm

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const CloseButton = styled.span`
  align-self: flex-end;
  cursor: pointer;
  font-size: 24px;
`

const FormHeader = styled.h2`
  font-family: Urbanist, sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
`

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 10px;
  }
`

const VerifyButton = styled.button<{ isVerify: boolean }>`
background-color:transparent;
border:1px solid ${props => props.isVerify ? `green` : 'red'};
border-radius:10px;
color:${props => props.isVerify ? `green` : 'red'};
padding:4px;
cursor:pointer;
font-family: Urbanist, sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin-left:20px;
`;

const Label = styled.label`
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #424242;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 0.9rem;
  }
`

const Input = styled.input`
  padding-top: 10px;
  //   width: 98%;
  color: black;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  border: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 7px;
    font-size: 0.9rem;
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledRegionDropdown = styled(RegionDropdown)`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
`
const StyledCountryDropdown = styled(CountryDropdown)`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px; /* Ensure padding for smaller screens */

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0px;
  }
`

const ModalContent = styled.div`
                display: flex;
                flex-direction: column;
                background-color: white;
                color: black;
                font-family: Urbanist, sans-serif;
                padding: 20px;
                border-radius: 5px;
                min-width: 300px;
                max-width: 80%;
                height: auto;
                max-height: 90vh;
                overflow-y: auto;
                position: relative;
                transition: all 0.6s ease-in-out;
                @media (max-width: ${breakpoints.mobile}) {
                    padding: 15px;
                min-width: 280px;
  }

                @media (min-width: ${breakpoints.tablet}) {
                    max - width: 70%;
  }

                @media (min-width: ${breakpoints.desktop}) {
                    max - width: 60%;
  }
                `

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`
