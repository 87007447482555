import React from 'react'
import styled from 'styled-components'
import LogoImage from '../../atoms/Utlis/LogoImage'

const Container = styled.div`
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 2%;
  padding:30px;
background: #F8EDFA;
overflow: hidden;
width: 100%;

`

const SectionTitle = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 18px;
`

const Paragraph = styled.div`
  margin-bottom: 15px;
`

const Link = styled.a`
  color: var(--themeColor);
`

const List = styled.ul<{ type?: string }>`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ListItem = styled.li< { type?: string } >`
  list-style-type: ${({ type }) => (type === 'decimal' ? 'decimal' : 'disc')};
`

const EmailLink = styled.a`
  font-weight: 500;
  cursor: pointer;
  line-height: 28px;
  @media (min-width: 768px) {
    line-height: 33px;
    font-size: 16px;
  }
  font-size: 14px;
  &:hover {
    color: var(--themeColor);
  }
`
const HeaderSection = styled.div``;



const TermsCondition = () => {
    return (
        <Container>
            <HeaderSection>
                <LogoImage />
            </HeaderSection>
            <SectionTitle>1. Acceptance of Terms</SectionTitle>
            <Paragraph>
                By accessing and using <Link href='https://dasboard.kwikmedia.in/'>admin.kwikmedia.in</Link> ("Website"),
                you agree to comply with and be bound by the following terms and conditions ("Terms").
                If you do not agree to these Terms, please do not use our Website.
            </Paragraph>

            <SectionTitle>2. Use of the Website</SectionTitle>
            <List type="decimal">
                <ListItem><strong>Eligibility:</strong> You must be at least 18 years old or have reached the age of majority in your jurisdiction to use this Website.
                    By using this Website, you represent and warrant that you meet these eligibility requirements.</ListItem>
                <ListItem><strong>License to Use:</strong> We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our Website for personal and commercial purposes, subject to these Terms.</ListItem>
                <ListItem><strong>Prohibited Conduct:</strong> You agree not to engage in any of the following activities:</ListItem>
                <List type="disc">
                    <ListItem>Using the Website for any illegal or unauthorized purpose.</ListItem>
                    <ListItem>Posting or transmitting any content that is unlawful, harmful, threatening, abusive, defamatory, vulgar, obscene, or otherwise objectionable.</ListItem>
                    <ListItem>Attempting to interfere with or disrupt the operation of the Website, its servers, or networks connected to it.</ListItem>
                    <ListItem>Using any automated system, including "robots," "spiders," or "offline readers," to access the Website in a manner that sends more request messages to our servers than a human could reasonably produce in the same period of time.</ListItem>
                </List>
            </List>

            <SectionTitle>3. User Accounts</SectionTitle>
            <List type="decimal">
                <ListItem><strong>Account Creation:</strong> To access certain features of the Website, you may be required to create an account. You agree to provide accurate, complete, and up-to-date information when creating your account.</ListItem>
                <ListItem><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                    You agree to notify us immediately of any unauthorized use of your account.</ListItem>
                <ListItem><strong>Account Termination:</strong> We reserve the right to terminate your account at our sole discretion if we believe you have violated these Terms or engaged in fraudulent or harmful activities.</ListItem>
            </List>

            <SectionTitle>4. Intellectual Property</SectionTitle>
            <List type="decimal">
                <ListItem><strong>Ownership:</strong> All content, materials, and intellectual property on the Website, including but not limited to text,
                    graphics, logos, images, and software, are the property of kwikmedia
                    or its licensors and are protected by copyright, trademark, and other intellectual property laws.</ListItem>
                <ListItem><strong>Limited Use:</strong> You may not copy, reproduce, distribute, or create derivative works of any content from the Website without our prior written consent.</ListItem>
                <ListItem><strong>Trademarks:</strong> All trademarks, service marks, and logos used on the Website are the trademarks of kwikmedia or their respective owners.
                    You may not use any trademarks without the prior written consent of the owner.</ListItem>
            </List>

            <SectionTitle>5. Products and Services</SectionTitle>
            <List type="decimal">
                <ListItem><strong>Availability:</strong> All products and services offered on the Website are subject to availability and may be withdrawn or modified at any time without notice.</ListItem>
                <ListItem><strong>Pricing:</strong> We reserve the right to change the prices of our products and services at any time.
                    Prices for products are listed in [Currency] and include applicable taxes unless otherwise stated.</ListItem>
                <ListItem><strong>Orders:</strong> By placing an order on the Website, you agree to pay the total amount for the order, including any applicable taxes and shipping fees.
                    We reserve the right to refuse or cancel any order at our discretion.</ListItem>
            </List>

            <SectionTitle>6. Payment Terms</SectionTitle>
            <List type="decimal">
                <ListItem><strong>Payment Methods:</strong> We accept various payment methods, including [list accepted payment methods, e.g., credit cards, PayPal, etc.].
                    Payments are processed through secure third-party payment processors.</ListItem>
                <ListItem><strong>Billing Information:</strong> You agree to provide accurate and complete billing information. If we cannot process your payment, we may cancel your order.</ListItem>
                <ListItem><strong>Refunds and Returns:</strong> Please refer to our [Refund and Return Policy] for detailed information on refunds and returns.</ListItem>
            </List>

            <SectionTitle>7. Third-Party Links</SectionTitle>
            <Paragraph>The Website may contain links to third-party websites or services that are not owned or controlled by kwikmedia. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.
                We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</Paragraph>

            <SectionTitle>8. Disclaimers and Limitation of Liability</SectionTitle>
            <List type="decimal">
                <ListItem><strong>No Warranties:</strong> The Website is provided on an "as is" and "as available" basis without any warranties, express or implied.
                    We do not warrant that the Website will be uninterrupted, error-free, or secure.</ListItem>
                <ListItem><strong>Limitation of Liability:</strong> To the fullest extent permitted by law, kwikmedia and its affiliates, directors, employees,
                    and agents will not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Website,
                    including but not limited to damages for loss of profits, data, or goodwill.</ListItem>
            </List>

            <SectionTitle>9. Indemnification</SectionTitle>
            <Paragraph>You agree to indemnify, defend, and hold harmless kwikmedia and its affiliates, directors,
                employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney's fees, arising out of
                or in any way connected with your use of the Website, your violation of these Terms, or your violation of any rights of another.</Paragraph>

            <SectionTitle>10. Governing Law</SectionTitle>
            <Paragraph>These Terms and your use of the Website will be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of laws principles.
                You agree to submit to the exclusive jurisdiction of the courts located in [Your Jurisdiction] for the resolution of any disputes.</Paragraph>

            <SectionTitle>11. Changes to These Terms</SectionTitle>
            <Paragraph>We may update these Terms from time to time to reflect changes in our practices or legal requirements. The updated Terms will be posted on
                this page with the "Effective Date" updated accordingly. We encourage you to review these Terms periodically.</Paragraph>

            <SectionTitle>12. Contact Us</SectionTitle>
            <Paragraph>If you have any questions or concerns about these Terms, please contact us at:</Paragraph>
            <List type="disc">
                <ListItem><strong>Email:</strong>
                    <EmailLink href={`mailto:higcorona@gmail.com`}>
                        {/* mailto:hr@kwikmedia.in */}
                        higcorona@gmail.com
                    </EmailLink>
                </ListItem>
                <ListItem><strong>Phone:</strong>
                    <EmailLink href={`tel:+919099588349`}>
                        +91 9099588349
                        {/* tel:+917990345580 */}
                    </EmailLink>
                </ListItem>
                <ListItem><strong>Address:</strong>  Ventureboo LLP Surat - 395010 </ListItem>
            </List>

        </Container>
    )
}

export default TermsCondition
