




// import React, { useState, useRef, useEffect, Fragment } from 'react';
// import styled from 'styled-components';

// import previewImage from '../../../../assets/images/dummyImage.jpg'
// const ImageContainer = styled.div`
//   width: 200px;
//   height: 200px;
//   overflow: hidden;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// `;

// const StyledImage = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

// // Styled image component
// const WraperDiv = styled.div`
// height:100px;
// width:100px;
// display:flex;
// align-items: center;
// justify-content: center;
// border-radius: 4px;
// border: 0.5px solid #d1d1d1;
// `
// const PreviewImage = styled.img<{ loaded: boolean }>`
// ${props => props.loaded ? `

// height:100px;
// width:100px;
//     `: `

// height:100px;
// width:100px;
//     `}
// `

// const SkeletonImageDiv = styled.div`
// background-color: gray;
// width: 100px;
// height: 100px;
// `
// const SkeletonImage = styled.img`
// height:30px;
// width:30px;
// `;
// interface ImageWithBlobProps {
//     imageFile: File;
// }

// const ImageWithBlob: React.FC<ImageWithBlobProps> = ({ imageFile }) => {
//     const [blobUrl, setBlobUrl] = useState<string | null>(null);
//     const imgRef = useRef<HTMLImageElement | null>(null);
//     const observer = useRef<IntersectionObserver | null>(null);

//     const [loaded, setLoaded] = useState(false);


//     const handleImageLoaded = () => {
//         // console.log("called")
//         setLoaded(true);
//     };
//     useEffect(() => {
//         observer.current = new IntersectionObserver((entries) => {
//             entries.forEach((entry) => {
//                 if (entry.isIntersecting) {
//                     // Create blob when the image enters the viewport
//                     const url = URL.createObjectURL(imageFile);
//                     setBlobUrl(url);
//                 } else {
//                     // Revoke blob when the image leaves the viewport
//                     if (blobUrl) {
//                         URL.revokeObjectURL(blobUrl);
//                         setBlobUrl(null);
//                     }
//                 }
//             });
//         });

//         if (imgRef.current) {
//             observer.current.observe(imgRef.current);
//         }

//         return () => {
//             if (observer.current && imgRef.current) {
//                 observer.current.unobserve(imgRef.current);
//             }
//             if (blobUrl) {
//                 URL.revokeObjectURL(blobUrl);
//             }
//         };
//     }, [imageFile, blobUrl]);

//     return (
//         <>

//             {blobUrl && <Fragment>
//                 <WraperDiv>


//                     <PreviewImage
//                         src={!loaded ? previewImage : blobUrl}
//                         alt="Loaded content"
//                         loading='lazy'
//                         loaded={loaded}
//                         onLoad={() => handleImageLoaded()}
//                         ref={imgRef}
//                     />
//                 </WraperDiv>


//             </Fragment>}
//         </>
//     );
// };

// export default ImageWithBlob;




import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import previewImage from '../../../../assets/images/dummyImage.jpg'

import CancleIconPNG from '../../../../assets/Icons/cancelIcon.png'
import SingleImageModal from './SingleImageModal';
const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const PreviewImageDiv = styled.div`
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0.5px solid #d1d1d1;
`;

const RemoveButtonConatiner = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;

`;
const CancleIcon = styled.img`
height:25px;
width:25px;
`

interface ImageWithBlobProps {
    imageFile: File;
    index: number;
    handleRemoveFile: (index: number) => void;
}

const ImageWithBlob: React.FC<ImageWithBlobProps> = ({ imageFile, index, handleRemoveFile }) => {
    const [blobUrl, setBlobUrl] = useState<string | null>(null);
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [isFullImage, setIsFullImage] = useState(false);


    const handleImageLoaded = () => {
        // console.log("called")
        setLoaded(true);
    };

    useEffect(() => {
        // Only create the observer once when the component mounts
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !blobUrl) {
                    // Create the blob URL only once
                    console.log(index)
                    const url = URL.createObjectURL(imageFile);
                    setBlobUrl(url);
                }
            });
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        // Cleanup the observer when the component unmounts
        return () => {
            if (observer && imgRef.current) {
                observer.unobserve(imgRef.current);
            }
            if (blobUrl) {

                URL.revokeObjectURL(blobUrl);
            }
        };
    }, [imageFile, blobUrl]);
    useEffect(() => {
        if (blobUrl) {
            URL.revokeObjectURL(blobUrl);
            setBlobUrl(null);
        }
    }, [imageFile])
    return (
        <PreviewImageDiv>
            {isFullImage && blobUrl && <SingleImageModal imageUrl={blobUrl} onClose={() => setIsFullImage(false)} />}
            {/*  */}
            {/* {blobUrl && <PreviewImage
                src={!loaded ? previewImage : blobUrl}
                alt="Loaded content"
                loading='lazy'
                loaded={loaded}
                onLoad={() => handleImageLoaded()}
                ref={imgRef}
            /> */}

            <StyledImage ref={imgRef} src={blobUrl || previewImage} alt="Uploaded" onClick={() => setIsFullImage(true)} />
            <RemoveButtonConatiner onClick={(e) => {
                if (blobUrl) {
                    e.stopPropagation();
                    URL.revokeObjectURL(blobUrl);
                    handleRemoveFile(index)
                }
            }}>
                <CancleIcon src={CancleIconPNG} />
            </RemoveButtonConatiner>
            {/* } */}
        </PreviewImageDiv>
    );
};

export default ImageWithBlob;



