import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../../Redux/Hooks';
import { useNavigate } from 'react-router-dom';
import { showErrorToast } from '../../../atoms/Utlis/Toast';
import BackIconPNG from '../../../../assets/Icons/SingleAlbum/back.png'
import previewImage from '../../../../assets/images/dummyImage.jpg'
import { Folder } from '../../../../Data/album.dto';
import UnderLine from '../../../atoms/Login/UnderLine';
import AddImageIconPNG from '../../../../assets/Icons/SingleAlbum/addImage.png'

import LoadingDots from '../../../atoms/Utlis/LoadinDots';
import SelectedIconPNG from '../../../../assets/Icons/tick.png'
import DeletePopup from '../../../atoms/Dashboard/Folder/DeletePopup';
import { deleteFolderImagesAPI, getSingleFolderAPI, updateFolderAPI, updateFolderNameAPI } from '../../../../Redux/ApiCalls/Dashboard/FolderApi';
import DiscardPopUp from '../../../atoms/Dashboard/Folder/DiscardSelectedImagesPopup';
import { addToCompressingFolder, removeCurrentFolder, removeFromCompressingFolder } from '../../../../Redux/Slice/Dashboard/AlbumSlice';
import ImageWithSkeleton from '../../../atoms/Dashboard/Folder/ImageWraper';
import LikeIconPNG from '../../../../assets/Icons/likeIcon.png'
import DeleteIconPNG from '../../../../assets/Icons/delete2.png'
import SeeAllImagesPNG from '../../../../assets/Icons/DropDownMenu/seeAllImages.png'
import RedLikeIcon from '../../../../assets/Icons/purpleHeart.png'
import { RedeemUsers } from '../../../../Data/user.dto';
import SelectRedeemUserListPopup from '../../../atoms/Dashboard/Folder/SelectRedeemUserListPopup';
import { changeAuthorizedRedeemUserAPI } from '../../../../Redux/ApiCalls/Dashboard/AlbumAPI';
import ImageWithBlob from '../../../atoms/Dashboard/Folder/UploadedImage';
import Errortext from '../../../atoms/Utlis/Errortext';
import { Modal } from 'antd';
import UploadImageModal from '../../../atoms/Dashboard/Folder/UploadImageModal';
import UploadImageModalComponent from '../../../atoms/Dashboard/Folder/UploadImageModal';
const PageConatiner = styled.div`
margin-left: 30px;
`;
const PageHeader = styled.div`
display:flex;
align-items: center;
`;
const BackIcon = styled.img`
height:20px;
width:20px;
cursor:pointer;
`;
const BackText = styled.p`
font-family: Urbanist;
font-size: 19px;
font-weight: 600;
line-height: 22.8px;
text-align: left;
margin-left: 10px;
cursor:pointer;
`;
const PageBoady = styled.div`

min-height: 700px;
 width:98%;

 margin-top:10px;
 background-color: hsla(0, 0%, 100%, 0.8);
 border-radius:10px;
position:relative;
 hr{
 margin-top:40px;
 opacity:0.4;
 width:98%;
 }
`;
const Conatiner1 = styled.div`
 
hr{
  margin:0;
  margin-left:30px;
  opacity:1;
  width:400px;
 }
`;
const InputNameConatiner = styled.div`
display:flex;
flex-direction: column;
align-items: flex-start;
padding:30px 30px 0px 30px;
`;
const InputName = styled.input`
font-family: Urbanist;
font-size: 19px;
font-weight: 600;
line-height: 22.8px;
text-align: left;
margin-top:20px;
width:500px;
border:none;
background:transparent;
 &:focus{
  outline:none;
 }
`;
const InputFolderLabel = styled.label`
font-family: Urbanist;
font-size: 19px;
font-weight: 600;
line-height: 22.8px;
text-align: left;
opacity: 0.5;

`;
const Conatiner2 = styled.div`
margin-left:30px;
display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  overflow-y: scroll;
   max-height: 400px;
  //  min-height: 500px;
`;
const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   border: 2px dashed #ccc;
  border-radius: 8px;
  background: #0000000D;

  cursor: pointer;
  width: 99px;
height: 99px;
border-radius: 19px;

`;
const AddImageLabel = styled.p`
color: #929292;
font-family: Urbanist;
font-size: 13px;
font-weight: 600;
line-height: 12px;
text-align: left;

`

const DefaultImage = styled.img`
  width: 43px;
  height: 41px;
 
`;
const ImagePreview = styled.div`
  position: relative;
  cursor: pointer;
`;
const LoadingPreviewConatiner = styled.div`
height: 50px;
width: 50px;
border:1px solid gray;
`
const PreviewImage = styled.img`
height:100px;
width:100px;
border-radius: 4px;
border: 0.5px solid #d1d1d1;
`

const RemoveButtonConatiner = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;

`;
const CancleIcon = styled.img`
height:25px;
width:25px;
`

const SelectedIconContainer = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  
  box-shadow: 0px 4px 14px 0px #00000080;


height:19px;
width:19px;
`;

const LikedImageContainer = styled.div`
      position: absolute;
    top: 3px;
    left: 3px;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d3c1d2e3;
    height: 26px;
    width: 30px;
`;
const SelectedIcon = styled.img`
height:19px;
width:19px;
`
const ButtonContainer = styled.div`
position: absolute;
width:100%;
display:flex;
align-items: center;
justify-content: center;
bottom: 30px;
`;
const UpdateButton = styled.button`
width:291px;
height:50px;
border-radius: 11px;
font-family: Urbanist;
font-size: 19px;
font-weight: 500;
line-height: 26.4px;
text-align: center;
color:white;
background: linear-gradient(360deg, #7A11A1 0%, #C62BC9 100%);
border:none;
cursor:pointer;
&:disabled{
 background:#bcbaba;
    color:black;
}

`;
const DeleteButton = styled.button`
width:291px;
height:50px;
border-radius: 11px;
border:none;
background-color:red;
font-family: Urbanist;
font-size: 19px;
font-weight: 500;
line-height: 26.4px;
text-align: center;
color:white;

cursor:pointer;
&:disabled{
 background-color:#bcbaba;
    color:black;
}
`;
const LoadingContainer = styled.div`

 width:98%;

 margin-top:40px;
 display: flex;
 align-items: center;
 justify-content: center;
`;
const InputContainer = styled.div`
display: flex;
width: 100%;
align-items: center;

`;

const SelectImageRadioConatiner = styled.div`
width:100%;
text-align:right;
margin-right:30px;
cursor:pointer;
`;
const SelectImageLabel = styled.label<{ isCheck: boolean }>`
font-family: Urbanist;
font-size: 16px;
font-weight: 500;
line-height: 26.4px;
text-align: center;
cursor:pointer;
color:${props => props.isCheck ? 'black' : 'grey'}

`;
const SlectImageRadio = styled.input``;

const MenuButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: #ffffffb2;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45px;
  height: 45px;
  // border: 1px solid white;
  cursor: pointer;
  border: none;
  box-shadow: 0px 20px 20px 0px hsla(259, 49%, 33%, 0.15);
`
const Dot = styled.p`
  color: hsla(293, 71%, 43%, 1);
  line-height: 8px;
  margin: 0px;
  font-size: 2rem;
`
const DropdownMenu = styled.div`
  position: absolute;
  top: 63px;
  right: 16px;
  background: white;
  width: 207px;
  height: 164px;
  z-index: 5;
  border-radius: 10px;
  box-shadow: 0px 4px 24px 0px hsla(0, 0%, 0%, 0.5);
`
const MenuItem = styled.div`
  padding: 2px 16px;
  height: 50px;

  cursor: pointer;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ItemIcon = styled.img`
  width: 34px;
  height: 30px;
`

const ItemName = styled.p`
  margin-left: 14px;
  width: 100%;
  height: 23px;
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
`
const Hr = styled.hr`
  margin: 0;
  width:100% !important;
  margin-left:0 !important;
  color: #5b463e;
`
const LikeIcon = styled.img`
height:23px;
width:21px;

`;

let urls: string[] = [];
const UpdateFolderPage = () => {
  const dispatch = useAppDispatch();
  const { currentFolder, isError, isFolderChange, redeemUsers, folderLoading, error, updatedFolderList, uploadFolderProgress, currentAlbum } = useAppSelector(state => state.album)
  const [folder, setFolder] = useState<Folder>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [newFolderImages, setNewFolderImages] = useState<File[]>([]);
  const [folderName, setFolderName] = useState<string>('');
  const [selectedFolderImages, setSelectedFolderImages] = useState<number[]>([]);
  const [folderImages, setFolderImages] = useState<{ id: number, thumbnail: string, project_id: number, image: string, media_type: number }[]>()
  const [compresedImageLoading, setCompresedImageLoading] = useState(false);
  const [activeUploadButton, setActiveUploadButton] = useState(true);
  const [deletModal, setDeleteModal] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string[]>([]);
  const [likedImages, setLikedImages] = useState<{
    id: number, thumbnail: string, project_id: number, image: string
  }[]>([]);
  const [uploadImageModal, setUploadImageModal] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [discardPopup, setDiscardPopup] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const [redeemUserPopup, setRedeemUserPopup] = useState(false);
  const [currentRedeemUsers, setCurrentRedeemUsers] = useState<RedeemUsers[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [isSeeAllImages, setIsSeeAllImages] = useState(true);
  const [displayedImages, setDisplayImages] = useState<{
    thumbnail: string; id: number, project_id: number, image: string, media_type: number
  }[]>([])
  const navigate = useNavigate();
  useEffect(() => {



    if (!currentFolder) {
      navigate(-1);

    } else {
      setFolder(currentFolder)
      setNewFolderImages([])
      setFolderName(currentFolder.name);
      setFolderImages(currentFolder.images);
      setDisplayImages(currentFolder.images.slice(0, 55))
      setSelectedFolderImages([])

    }

  }, []);
  useEffect(() => {
    // Set the 'webkitdirectory' attribute after the component is rendered
    if (fileInputRef.current) {
      fileInputRef.current.setAttribute("webkitdirectory", "true");
    }
  }, []);

  useEffect(() => {
    if (currentAlbum && currentAlbum.liked_images && currentFolder) {
      let likedImages = currentAlbum.liked_images.filter(image => {
        if (image.folder_id === currentFolder.id) {

          return {
            id: image.id,
            project_id: image.project_id,
            folder_id: image.folder_id,
            thumbnail: image.thumbnail,
            image: image.image,


          }
        }
      })
      if (likedImages && likedImages.length > 0) {
        setLikedImages(likedImages)
      } else {
        setLikedImages([]);
      }
    } else {
      setLikedImages([]);
    }
  }, [currentAlbum?.liked_images])

  useEffect(() => {
    // const worker = new MyWorker('')
    if (redeemUsers && redeemUsers.length > 0) {
      setCurrentRedeemUsers(redeemUsers);
    } else {
      setCurrentRedeemUsers([]);
    }
    return () => {
      // setPreviewImageUrl([])
    }

  }, [redeemUsers]);

  useEffect(() => {

    isUploadActiveButton();
  }, [newFolderImages, folderName])

  useEffect(() => {
    // // console.log("calling ++++")
    if (currentFolder) {
      if (updatedFolderList.find(folder => folder.folder_id === currentFolder.id)) {

        setSelectedFolderImages([])
        setIsUpdate(false);
        setIsImageSelected(false);
        setNewFolderImages([]);
        dispatch(getSingleFolderAPI({ folder_id: currentFolder?.id, page: 1, page_size: 50 }));
      }
    }
  }, [updatedFolderList])
  useEffect(() => {

    if (uploadFolderProgress && uploadFolderProgress.length > 0 && currentFolder && uploadFolderProgress.find(f => f.folderId === currentFolder.id)) {
      setNewFolderImages([]);
      setPreviewImageUrl([]);
      navigate(-1);

    }

    return () => {
      urls.forEach(link => URL.revokeObjectURL(link))
    }
  }, [uploadFolderProgress])
  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuOpen])
  useEffect(() => {

    if (!observer.current && newFolderImages.length > 0) {
      observer.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const imageId = parseInt(entry.target.getAttribute('data-id')!);
          console.log(imageId, previewImageUrl[imageId])
          if (entry.isIntersecting && previewImageUrl[imageId] === undefined) {
            // const file = images.find((img) => img.id === parseInt(imageId))?.file;
            // console.log("calling inside if condition ")
            setPreviewImageUrl(pre => {
              pre[imageId] = URL.createObjectURL(newFolderImages[imageId]);
              return pre;
            })
          }
        });
      }, {
        threshold: 0.1,
      });
    }
  }, [newFolderImages]);
  useEffect(() => {
    if (currentFolder && currentFolder.images) {
      setFolderImages(currentFolder.images)
    }
  }, [currentFolder?.images])

  const handleImageUpload = async (files: File[]) => {

    if (!files) return;
    if (files.length + newFolderImages.length > 20000) {
      showErrorToast('You can only select up to 900 images at a time.');
      setUploadImageModal(false);
      return;
    } else {
      console.log("files", files)
      setCompresedImageLoading(true);
      setUploadImageModal(false);
      const fileArray = Array.from(files);
      const chunkSize = Math.ceil(fileArray.length / 2);
      const imageChunks = splitArrayIntoChunks(fileArray, chunkSize);
      const totalFilesLength = fileArray.length;
      let totalCompletedFiles = 0
      if (currentFolder)
        dispatch(addToCompressingFolder({ folderId: currentFolder.id, folderName: currentFolder.name, totalProgress: 0.01 }))
      let workersThread: any = []
      const workerPromises = imageChunks.map((chunk, index) => {
        return new Promise<File[]>((resolve) => {
          const worker = new Worker(new URL("./myWorker.worker.ts", import.meta.url))
          workersThread.push(worker);

          worker.onmessage = (e) => {
            // console.log(e)
            if (e.data.type === "progress") {
              totalCompletedFiles += e.data.completedFiles;
              if (currentFolder)
                dispatch(addToCompressingFolder({ folderId: currentFolder.id, folderName: currentFolder.name, totalProgress: (totalCompletedFiles / totalFilesLength) * 100 }))
            } else if (e.data.type === "complete") {
              resolve(e.data.files);

            }
          };

          worker.postMessage({ files: chunk, work: index });
        });
      });
      const result = await Promise.all(workerPromises);
      const compressedFiles = result.flat()
      setCompresedImageLoading(false);

      // Filter out any undefined values

      const validCompressedFiles = compressedFiles.filter((file): file is File => file !== undefined);
      console.log(validCompressedFiles)

      setNewFolderImages((prev) => [...prev, ...validCompressedFiles]);
      if (currentFolder)
        dispatch(removeFromCompressingFolder(currentFolder.id))
      workersThread.forEach((worker: any) => {
        worker.terminate();
      })
    }
  };
  const splitArrayIntoChunks = (arr: any[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    console.log("chunks")
    console.log(chunks)
    return chunks;
  };

  const handleRemoveImage = (index: number) => {
    setNewFolderImages((prev) => {
      const updatedImages = [...prev];
      updatedImages.splice(index, 1); // Remove the image at the given index
      return updatedImages;
    });

  };

  const handleSelectedImage = (id: number, url: string) => {
    setSelectedFolderImages((prev) =>
      prev.includes(id) ? prev.filter((imageId) => imageId !== id) : [...prev, id]
    )
  }
  const isUploadActiveButton = () => {
    if (folderName !== folder?.name || newFolderImages.length !== 0) {
      setActiveUploadButton(false);
    } else {
      setActiveUploadButton(true);
    }
  }
  const handleUpdateFolder = () => {

    if (folder && isValidName(folderName)) {
      if (newFolderImages.length > 0)
        dispatch(updateFolderAPI({ project_id: folder.project_id, id: folder?.id, folder_name: folderName, folderImages: newFolderImages }));
      else {
        dispatch(updateFolderNameAPI({ project_id: folder.project_id, id: folder?.id, folder_name: folderName }))

      }
    } else {
      setShowError(true);
    }
    // formData.append('folder_id', `${folder?.id}`);
    // if (folderName)
    //   formData.append('folder_name', folderName);
    // else
    //   formData.append('folder_name', `${folder?.name}`);
    // newFolderImages.forEach((image, index) => {
    //   formData.append(`media[${index}][image]`, image);
    //   formData.append(`media[${index}][media_type]`, "1");
    // })

  }
  const deleteFolderImages = () => {
    if (currentFolder)
      dispatch(deleteFolderImagesAPI({ media_ids: selectedFolderImages, folder_id: currentFolder.id, project_id: currentFolder.project_id }));

    setDeleteModal(false);
  }
  const SelectCheckbox = () => {
    // // console.log(isImageSelected)
    if (!isImageSelected) {
      if (newFolderImages.length > 0 || currentFolder?.name !== folderName) {

        // setIsImageSelected(true);
        setDiscardPopup(true);
      } else {
        setIsUpdate(false);
        setIsImageSelected(true)
      }
    } else {
      setIsImageSelected(false);
      setSelectedFolderImages([]);
      // // console.log(selectedFolderImages)
    }
  }
  const ImageUploadCheck = () => {
    if (isImageSelected) {
      if (selectedFolderImages.length > 0) {
        setDiscardPopup(true);
      } else {
        setIsImageSelected(false);
        if (fileInputRef.current)
          fileInputRef.current.value = '';
        setUploadImageModal(true);
        setIsUpdate(true);

      }
    } else {
      if (fileInputRef.current)
        fileInputRef.current.value = '';
      setUploadImageModal(true);
      setIsUpdate(true);
    }
  }
  const DiscardAllChanges = () => {
    setIsUpdate(false);
    if (currentFolder)
      setFolderName(currentFolder.name);
    setSelectedFolderImages([]);
    setNewFolderImages([]);
    setIsImageSelected(false);
    setDiscardPopup(false);
  }
  const folderNameChange = (name: string) => {
    if (name.length <= 30) {

      if (name !== currentFolder?.name) {

        if (isImageSelected) {
          if (selectedFolderImages.length > 0) {
            setDiscardPopup(true);
          } else {
            setIsImageSelected(false)
            setFolderName(name);
            setIsUpdate(true);

          }
        } else {
          // fileInputRef?.current?.click()
          setIsUpdate(true);
          setFolderName(name);
        }
      } else {
        setIsUpdate(false);
        setFolderName(currentFolder?.name);
        // setFolderName(name);

      }
    }
  }

  const loadMoreImages = () => {
    if (currentFolder && folderImages) {
      const currentLength = folderImages.length;
      if (currentLength < currentFolder.total_images && !folderLoading) {
        dispatch(getSingleFolderAPI({ folder_id: currentFolder?.id, page: currentPage + 1, page_size: 50 }));
        setCurrentPage(pre => pre + 1);
      }
    }

  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if ((scrollHeight - scrollTop) <= (clientHeight + 100)) {
      loadMoreImages()
    }
  };


  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false)
    }
  }
  const openMenu = (e: React.MouseEvent) => {
    e.stopPropagation()
    setMenuOpen((prev) => !prev)
  }

  const getLikedImagesForRedeemUsers = (id: number) => {
    if (currentFolder && currentAlbum) {
      dispatch(changeAuthorizedRedeemUserAPI({ album_id: currentFolder.project_id, user_id: id, upgrade_type: 'allow_access' }))
      setRedeemUserPopup(false);
      setIsSeeAllImages(false);
    }
  }

  const isValidName = (name: string) => {
    if (name.trim().length > 0 && name.trim().length < 40) return true;
    else return false;
  }
  return (
    <PageConatiner>
      {discardPopup && <DiscardPopUp cancel={() => setDiscardPopup(false)} Delete={() => DiscardAllChanges()} />}
      <PageHeader>
        <BackIcon src={BackIconPNG} onClick={() => navigate(-1)} />
        <BackText onClick={() => navigate(-1)}>Back</BackText>
      </PageHeader>

      <PageBoady>
        <Conatiner1>
          {deletModal && <DeletePopup buttonText='Delete' cancel={() => setDeleteModal(false)} Delete={() => deleteFolderImages()} text={'Are you sure you want to remove from favorites?'} />}
          {redeemUserPopup && <SelectRedeemUserListPopup showFunction={(id: number) => getLikedImagesForRedeemUsers(id)} redeemUser={currentRedeemUsers} closeModal={() => setRedeemUserPopup(false)} />}
          <MenuButton ref={buttonRef} onClick={openMenu}>
            <Dot>.</Dot>
            <Dot>.</Dot>
            <Dot>.</Dot>
          </MenuButton>
          {menuOpen && (
            <DropdownMenu ref={menuRef} onClick={(e) => e.stopPropagation()}>
              <MenuItem
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  setRedeemUserPopup(true);
                  setMenuOpen(false);
                }}
              >
                <ItemIcon src={LikeIconPNG} />
                <ItemName>Liked Images</ItemName>
              </MenuItem>
              <Hr />
              <MenuItem
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  SelectCheckbox();
                  setMenuOpen(false);
                }}
              >
                <ItemIcon src={DeleteIconPNG} />
                <ItemName>Delete Images</ItemName>
              </MenuItem>
              <Hr />
              <MenuItem onClick={(e) => {
                e.stopPropagation();
                setIsSeeAllImages(true);
              }}>
                <ItemIcon src={SeeAllImagesPNG} />
                <ItemName>See All Images</ItemName>
              </MenuItem>

            </DropdownMenu>
          )}
          <InputNameConatiner >
            <InputFolderLabel>Folder</InputFolderLabel>

            <InputName type='text' value={folderName} onChange={(e) => folderNameChange(e.target.value)} />
          </InputNameConatiner>
          <UnderLine width={100} isPercent={true} />
          <div style={{ marginLeft: "30px" }}>

            <Errortext show={showError && !isValidName(folderName)} message='Please enter valid folder name.' />
          </div>
        </Conatiner1>
        <UnderLine width={900} />
        <InputContainer>
          <SelectImageRadioConatiner>
            <SlectImageRadio type='checkbox' id="selecheck" onChange={() => SelectCheckbox()} checked={isImageSelected} />
            <SelectImageLabel htmlFor='selecheck' isCheck={isImageSelected}>Select Images</SelectImageLabel>
          </SelectImageRadioConatiner>
        </InputContainer>
        <Conatiner2 onScroll={handleScroll}>
          <ImageUploadContainer onClick={() => { if (!compresedImageLoading) ImageUploadCheck() }}>
            {
              compresedImageLoading ?
                <LoadingDots /> :
                <>
                  <DefaultImage src={AddImageIconPNG} alt="Click to upload" />
                  <AddImageLabel>{compresedImageLoading ? 'Please Wait...' : "Add Images"}</AddImageLabel>
                </>
            }
            <input
              id="image-upload"
              type="file"
              accept=".jpg, .jpeg, .png"
              multiple
              ref={fileInputRef}


              style={{ display: 'none' }}
            />
          </ImageUploadContainer>
          {compresedImageLoading ? null :
            <>{
              newFolderImages.map((file, index) => (
                <ImagePreview key={index}>

                  {<ImageWithBlob key={index} imageFile={file} index={index} handleRemoveFile={handleRemoveImage} />}
                </ImagePreview>
              ))
            }</>

          }
          {
            folderImages && folderImages.length !== 0 && displayedImages.length !== 0 ?
              isSeeAllImages ?
                folderImages.map((image, index) => (
                  <ImagePreview key={index} >
                    <ImageWithSkeleton onClick={() => {
                      if (isImageSelected) {

                        handleSelectedImage(image.id, image.image)
                      }
                    }} imgURL={`${process.env.REACT_APP_CLOUD_FRONT_DOMAIN_URL}/${image.image}`} isImagesSelected={isImageSelected} thumbnailURL={`${process.env.REACT_APP_CLOUD_FRONT_DOMAIN_URL}/${image.thumbnail}`} />
                    {/* <PreviewImage src={image.image} alt="preview" loading='lazy' /> */}


                    {
                      isImageSelected && <SelectedIconContainer  >
                        {
                          selectedFolderImages.includes(image.id) && <SelectedIcon src={SelectedIconPNG} />
                        }
                      </SelectedIconContainer>
                    }

                  </ImagePreview>
                ))
                :
                likedImages && likedImages.length > 0 && likedImages.map((image, index) => (

                  <ImagePreview key={index} >
                    <ImageWithSkeleton onClick={() => {
                      if (isImageSelected) {

                        handleSelectedImage(image.id, image.image)
                      }
                    }} imgURL={`${process.env.REACT_APP_CLOUD_FRONT_DOMAIN_URL}/${image.image}`} isImagesSelected={isImageSelected} thumbnailURL={`${process.env.REACT_APP_CLOUD_FRONT_DOMAIN_URL}/${image.thumbnail}`} />
                    {/* <PreviewImage src={image.image} alt="preview" loading='lazy' /> */}

                    <LikedImageContainer>
                      <LikeIcon src={RedLikeIcon} />
                    </LikedImageContainer>

                    {
                      isImageSelected && <SelectedIconContainer  >
                        {
                          selectedFolderImages.includes(image.id) && <SelectedIcon src={SelectedIconPNG} />
                        }
                      </SelectedIconContainer>
                    }

                  </ImagePreview>
                ))
              : null
          }

        </Conatiner2>
        <ButtonContainer>
          {folderLoading ? <LoadingContainer><LoadingDots /> </LoadingContainer> :
            <>
              {isUpdate && !isImageSelected &&
                <UpdateButton disabled={activeUploadButton} onClick={handleUpdateFolder}>
                  Update
                </UpdateButton>}

              {
                isImageSelected && !isUpdate &&
                <DeleteButton disabled={selectedFolderImages.length === 0} onClick={() => setDeleteModal(true)}>
                  Delete {selectedFolderImages.length !== 0 ? `(${selectedFolderImages.length})` : null}
                </DeleteButton>
              }
            </>


          }
        </ButtonContainer>
      </PageBoady>
      {
        <Modal
          onCancel={() => setUploadImageModal(false)}
          open={uploadImageModal}
          footer={null}
          title="Upload Images"

        >
          <UploadImageModalComponent
            handleImageUpload={handleImageUpload}
            closeModal={() => setUploadImageModal(false)}
          />
        </Modal>
      }
    </PageConatiner >

  )
}

export default UpdateFolderPage
