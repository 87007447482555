
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';


const sendFirebaseEvent = (title: string, category: string, action: string, label: any) => {
    logEvent(analytics, title, {
        category,
        action,
        label: label.toString(),

    })
}


export default sendFirebaseEvent;
