import React from 'react'
import styled from 'styled-components'
import { ActivePlan } from '../../../../Data/subscription.dto'
import UnderLine from '../../Login/UnderLine'

import CancelIconPNG from '../../../../assets/Icons/cancelIcon.png'

import TickIconPNG from '../../../../assets/Icons/tick.png'
import SubmitButton from '../../Login/SubmitButton'
interface CurrentPlanProps {
    plan: ActivePlan
}

const CurrentPlan: React.FC<CurrentPlanProps> = ({ plan }) => {

    const formatToINR = (amount: number): string => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0,
        }).format(amount);
    };
    function formatDate(dateString: string) {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    }



    return (
        <Container>
            <HeaderContainer>
                <HeaderText>Current Plan</HeaderText>
            </HeaderContainer>
            <BodyContainer>
                <PlanDetailsContainer>
                    <PlanDetailsHeader>Plan Details</PlanDetailsHeader>
                    <DataContainer>
                        <LabelDiv>

                            <Label>Current Plan : &nbsp;</Label>
                        </LabelDiv>
                        <PlanDetailText> {plan.storage_plan_id.name === "free_plan" ? "Free Plan" : plan.storage_plan_id.name} </PlanDetailText>
                    </DataContainer>
                    <UnderLine isPercent={true} width={100} />
                    <DataContainer>
                        <LabelDiv>

                            <Label>Current Plan Price : &nbsp;</Label>
                        </LabelDiv>
                        <PlanDetailText> {formatToINR(plan.storage_plan_id.offer_price * plan.storage_plan_id.plan_pack_duration)} / {plan.storage_plan_id.plan_pack_duration} Months </PlanDetailText>
                    </DataContainer>
                    <UnderLine isPercent={true} width={100} />
                    <DataContainer>
                        <LabelDiv>

                            <Label> Paid Price : &nbsp;</Label>
                        </LabelDiv>
                        <PlanDetailText> {formatToINR(plan.amount)} </PlanDetailText>
                    </DataContainer>
                    <UnderLine isPercent={true} width={100} />
                    <DataContainer>
                        <LabelDiv>
                            <Label> Payment Date : &nbsp;</Label>
                        </LabelDiv>
                        <PlanDetailText> {formatDate(plan.created_at)} </PlanDetailText>
                    </DataContainer>
                    <UnderLine isPercent={true} width={100} />
                    <DataContainer>
                        <LabelDiv>

                            <Label> Validity : &nbsp;</Label>
                        </LabelDiv>
                        <PlanDetailText> {plan.plan_validity} Months</PlanDetailText>
                    </DataContainer>
                    <UnderLine isPercent={true} width={100} />
                    <DataContainer>
                        <LabelDiv>
                            <Label> Expired At : &nbsp;</Label>
                        </LabelDiv>
                        <PlanDetailText> {formatDate(plan.expired_at)} </PlanDetailText>
                    </DataContainer>
                    <UnderLine isPercent={true} width={100} />
                </PlanDetailsContainer>
                <VerticalLine />
                <PlanFeaturesContainer>
                    <PlanDetailsHeader>Plan Features</PlanDetailsHeader>
                    <FeatureListContainer>

                        <DescriptionItemContainer>
                            <ItemIcon src={TickIconPNG} />

                            <DescriptionItem >
                                <Label>Album Limit : &nbsp; {plan.storage_plan_id.project_limit}</Label>
                            </DescriptionItem>
                        </DescriptionItemContainer>
                        <DescriptionItemContainer>
                            <ItemIcon src={TickIconPNG} />

                            <DescriptionItem >
                                <Label>Share  to {plan.storage_plan_id.project_limit} People</Label>
                            </DescriptionItem>
                        </DescriptionItemContainer>
                        <DescriptionItemContainer>
                            {!plan.storage_plan_id.event_management ? <CancelIcon src={CancelIconPNG} /> : <ItemIcon src={TickIconPNG} />}

                            <DescriptionItem >
                                <Label>Event Scheduling</Label>
                            </DescriptionItem>
                        </DescriptionItemContainer>
                        <DescriptionItemContainer>
                            {!plan.storage_plan_id.packages ? <CancelIcon src={CancelIconPNG} /> : <ItemIcon src={TickIconPNG} />}

                            <DescriptionItem >
                                <Label>Studio Packages</Label>
                            </DescriptionItem>
                        </DescriptionItemContainer>
                        <DescriptionItemContainer>
                            {!plan.storage_plan_id.user_form ? <CancelIcon src={CancelIconPNG} /> : <ItemIcon src={TickIconPNG} />}

                            <DescriptionItem >
                                <Label>Customer Event Management</Label>
                            </DescriptionItem>
                        </DescriptionItemContainer>
                    </FeatureListContainer>
                </PlanFeaturesContainer>
            </BodyContainer>
        </Container>
    )
}

export default CurrentPlan


const Container = styled.div`
width:100%;
height:100%;
background-color:rgb(35, 29, 79);
color:white;
border-radius:16px;
`;

const HeaderContainer = styled.div`
padding-top:10px;
Width:100%;

`;

const HeaderText = styled.p`

font-family: Urbanist;
font-size: 28px;
font-style: italic;
font-weight: 700;
line-height: 33.6px;
text-align: center;
margin:0px;
`;

const BodyContainer = styled.div`
display:flex;
width: 100%;
margin-top:30px;

`;


const PlanDetailsContainer = styled.div`
width:50%;
height:81%;
padding:10px;
`;

const PlanFeaturesContainer = styled.div`
width:50%;
height:81%;
padding:10px;
`;

const DataContainer = styled.div`
display:flex;
align-items: center;
width:100%;

`;

const Label = styled.label`

font-family: Urbanist;
font-size: 18px;
font-style: italic;
font-weight: 600;
line-height: 33.6px;
text-align: center;

color:white;
margin:0px;
`;

const PlanDetailText = styled.p`
font-family: Urbanist;
font-size: 18px;
font-weight: 500;
line-height: 33.6px;
text-align: center;
margin:0px;
`;


const PlanDetailsHeader = styled.p`

font-family: Urbanist;
font-size: 23px;
font-style: italic;
font-weight: 600;
line-height: 33.6px;
text-align: center;
margin-top:0;

`;

const LabelDiv = styled.div`
width:50%;
`;

const VerticalLine = styled.hr`
width:0px;
background-color:gray;
`;


const DescriptionItemContainer = styled.div`
display: flex;
margin-top:18px;
align-items: center;

`;
const ItemIcon = styled.img`
width: 20px;
height: 20px;
margin-left:3px;
`;


const CancelIcon = styled.img`
width: 26px;
height: 26px;

`;


const DescriptionItem = styled.p`
font-family: Urbanist;
font-size: 15px;
font-weight: 500;
line-height: 18px;
text-align: left;
margin: 0px;
margin-left:10px;
color:#848199 ;


`;
const FeatureListContainer = styled.div`
width:70%;
height:100%;
display:flex;
flex-direction: column;

`;

const ExploreButtonContainer = styled.div`
width:100%;
display:flex;
align-items: center;
justify-content: center;
margin-top: 50px;
`;