import zIndex from '@mui/material/styles/zIndex';
import React from 'react'
import Select from 'react-select'
import styled from 'styled-components';

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? '#AE2AB1' : 'purple', // Focus border color
        boxShadow: state.isFocused ? '0 0 0 1px #AE2AB1' : 'none', // Focus box shadow

        // Hover border color
        height: 50, // Height of the control box
        width: '100%', // Full width of the container or you can specify a fixed width
        minHeight: 55,
        marginTop: 10,
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? '#f0f0f0'
            : state.isSelected
                ? 'linear-gradient(360deg, #9c44bd 0%, #c62bc9 100%)'
                : 'white', // Background color for focused, selected, and default states
        color: state.isSelected ? 'black' : state.isFocused ? '#AE2AB1' : 'black', // Text color
        '&:hover': {
            backgroundColor: '#c76dc9', // Hover background color
            color: 'white', // Text color on hover
        },
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: 'black', // Selected option color
    }),
};

const hoursOptions = Array.from({ length: 24 }, (_, i) => ({
    value: i + 1, // Options from 1 to 24 hours
    label: `${i + 1} `,
}));

interface HourPickerProps {
    value?: { value: number, label: string };
    onChange: (hour: number) => void;

}

const HourPicker: React.FC<HourPickerProps> = ({ value, onChange }) => {
    return (
        <Container >

            <Select
                options={hoursOptions}
                value={value}
                onChange={(option: any) => onChange(option.value)}
                placeholder="Select Event Duration (Hours)"
                styles={customStyles} // Applying custom styles here
            />
        </Container>
    )
}

export default HourPicker

const Container = styled.div`


`;

const HeaderContainer = styled.div`

`;
const HeaderText = styled.div``;