// ImageWithSkeleton.js
import React, { useState, useEffect, Fragment, useRef } from 'react';
import styled from 'styled-components';
import previewImage from '../../../../assets/images/dummyImage.jpg'
import SingleImageModal from './SingleImageModal';
import { image } from '@cloudinary/url-gen/qualifiers/source';
// Wrapper to contain the image and skeleton loader


// Styled image component
const WraperDiv = styled.div`
height:100px;
width:100px;
display:flex;
align-items: center;
justify-content: center;
border-radius: 4px;
border: 0.5px solid #d1d1d1;
`
const PreviewImage = styled.img<{ loaded: boolean }>`
${props => props.loaded ? `
    
height:100px;
width:100px;
    `: `

height:100px;
width:100px;
    `}
`

const SkeletonImageDiv = styled.div`
background-color: gray;
width: 100px;
height: 100px;
`
const SkeletonImage = styled.img`
height:30px;
width:30px;
`;
interface ImageWrapper {
    imgURL: string;
    isLiked?: boolean;
    isChanged?: boolean;
    thumbnailURL?: string;
    onClick: () => void;
    isImagesSelected: boolean;
}

const ImageWithSkeleton: React.FC<ImageWrapper> = ({ imgURL, isLiked = false, thumbnailURL, isImagesSelected, onClick }) => {

    const [loaded, setLoaded] = useState(false);
    const [url, setUrl] = useState<string>()
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [isFullImage, setIsFullImage] = useState(false);
    useEffect(() => {
        // Only create the observer once when the component mounts
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Create the blob URL only once
                    if (url !== imgURL)
                        setUrl(imgURL)
                }
            });
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        // Cleanup the observer when the component unmounts
        return () => {
            if (observer && imgRef.current) {
                observer.unobserve(imgRef.current);
            }

        };
    }, [imgURL]);
    const handleImageLoaded = () => {
        // console.log("called")
        setLoaded(true);
    };
    useEffect(() => {
        if (thumbnailURL)
            setUrl(thumbnailURL)
    }, [thumbnailURL])

    const handleClick = () => {
        if (isImagesSelected) {
            onClick()
        } else {
            // console.log("Open Big image...")
            setIsFullImage(true);
        }
    }
    return (
        <Fragment >
            <WraperDiv onClick={() => handleClick()}>

                {isFullImage && <SingleImageModal imageUrl={imgURL} thumbnailUrl={thumbnailURL} onClose={() => setIsFullImage(false)} />}
                <PreviewImage
                    src={!loaded ? previewImage : url}
                    alt="Preview Image"
                    loading='lazy'
                    loaded={loaded}
                    ref={imgRef}
                    onError={() => setUrl(thumbnailURL)}
                    onLoad={() => handleImageLoaded()}

                />
            </WraperDiv>


        </Fragment>
    );
};

export default ImageWithSkeleton;