import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Albums, Folder } from '../../../../Data/album.dto';
import UnderLine from '../../Login/UnderLine';
import SubmitButton from '../../Login/SubmitButton';
import apiCall from '../../../../Redux/ApiCalls/AuthorizedApi';
import { showErrorToast, showSuccessToast } from '../../Utlis/Toast';
import LoadingDots from '../../Utlis/LoadinDots';
import { dividerClasses } from '@mui/material';


interface AllowedUserModalPropsType {
    isAlbum: boolean;
    album?: Albums,
    folder?: Folder,
    onClose: () => void;
}


const AllowedUserModal: React.FC<AllowedUserModalPropsType> = ({ album, isAlbum, onClose, folder }) => {
    const [enteredContact, setEnteredContact] = useState<string>();
    const [contactList, setContactList] = useState<number[]>([]);
    const [loading, setLoading] = useState(false);
    const [isUpdated, setIsUpdated] = useState(true);
    const [folderContactList, setFolderContactList] = useState<number[]>([]);
    useEffect(() => {
        if (isUpdated) {
            if (isAlbum && album) {

                setLoading(true);
                apiCall({
                    method: 'GET',
                    url: `/project/allowed-user/?fetch_type=project&project_id=${album.id}`,


                }).then(res => {
                    setLoading(false);
                    setIsUpdated(false)
                    if (res.data)
                        setContactList(JSON.parse(res.data))
                }).catch(err => {
                    setIsUpdated(false)
                    console.log(err)
                    setLoading(false);
                })
            } else if (folder) {
                setLoading(true)
                apiCall({
                    method: 'GET',
                    url: `/project/allowed-user/?fetch_type=project&project_id=${folder.project_id}`,


                }).then(res => {


                    if (res.data)
                        setContactList(JSON.parse(res.data))
                    else
                        setContactList([])
                }).catch(err => {

                    console.log(err)

                })
                apiCall({
                    method: 'GET',
                    url: `/project/allowed-user/?fetch_type=folder&folder_id=${folder.id}`,


                }).then(res => {

                    setIsUpdated(false)
                    if (res.data)
                        setFolderContactList(JSON.parse(res.data))
                    else
                        setFolderContactList([])
                }).catch(err => {
                    setIsUpdated(false)
                    console.log(err)

                }).finally(() => setLoading(false))
            }
        }
    }, [isUpdated])


    const validatePhoneNumber = (phoneNumber: string): boolean => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phoneNumber);
    };
    const handleAddPhoneNumberAlbum = () => {
        if (enteredContact && validatePhoneNumber(enteredContact)) {
            if (isAlbum && album) {

                let formData = new FormData();
                formData.append('project_id', album.id.toString())
                formData.append('project_access', JSON.stringify([...contactList, parseInt(enteredContact)]));
                apiCall({
                    method: 'PUT',
                    url: `/project/user/project-media-view/`,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: formData
                }).then((res) => {
                    setIsUpdated(true);
                    showSuccessToast("Number added successfully.")
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        showErrorToast(err.response.data.message);
                    }
                })
            }
        }
    }
    const handleAddNumberInFolder = (index: number) => {
        if (folder) {
            console.log(contactList[index])
            apiCall({
                method: 'PUT',
                url: `/project/update-folder/`,
                data: {
                    upgrade_type: "member",
                    folder_access: JSON.stringify([...folderContactList, contactList[index]]),
                    folder_id: folder.id
                }
            }).then((res) => {
                setIsUpdated(true);
                showSuccessToast("Number added successfully.")
            }).catch((err) => {
                if (err.response && err.response.data) {
                    showErrorToast(err.response.data.message);
                }
            })
        }
    }

    const handleRemoveContact = (index: number) => {
        console.log(contactList.filter((con, ind) => index !== ind))
        if (isAlbum && album) {


            let formData = new FormData();
            formData.append('project_id', album.id.toString())
            formData.append('project_access', JSON.stringify(contactList.filter((con, ind) => index !== ind)));
            apiCall({
                method: 'PUT',
                url: `/project/user/project-media-view/`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData
            }).then((res) => {
                setIsUpdated(true);
                showSuccessToast("Number removed successfully.")
            }).catch((err) => {
                if (err.response && err.response.data) {
                    showErrorToast(err.response.data.message);
                }
            })
        } else if (folder) {
            apiCall({
                method: 'PUT',
                url: `/project/update-folder/`,
                data: {
                    upgrade_type: "member",
                    folder_access: JSON.stringify(folderContactList.filter((con, ind) => index !== ind)),
                    folder_id: folder.id
                }
            }).then((res) => {
                setIsUpdated(true);
                showSuccessToast("Number removed successfully.")
            }).catch((err) => {
                if (err.response && err.response.data) {
                    showErrorToast(err.response.data.message);
                }
            })
        }
    }
    return (
        <ModalOverlay>
            <ModalContent>
                <CloseButton onClick={() => onClose()}>&times;</CloseButton>
                <HeaderContainer>
                    <Title>{isAlbum && album ? album.name : folder?.name}</Title>


                    <SubTitle>(Allowed Users)</SubTitle>
                </HeaderContainer>
                <BodyContainer>
                    {loading ? <LoadingDots /> :

                        <>
                            {isAlbum && album ?

                                <NumberContainer>
                                    {contactList?.length > 0 ? contactList.map((contact, index) => (
                                        <>
                                            <ContactContainer >
                                                <ContactText>{index + 1}) {contact}</ContactText>
                                                <RemoveButton onClick={() => handleRemoveContact(index)}>Remove</RemoveButton>
                                            </ContactContainer>
                                            <UnderLine width={99} isPercent={true} />
                                        </>
                                    )) : <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>No Allowed user found.</div>}
                                </NumberContainer>
                                : folder ?
                                    <NumberContainer>
                                        {contactList?.length > 0 ? contactList.map((contact, index) => (
                                            <>
                                                <ContactContainer >
                                                    <ContactText>{index + 1}) {contact}</ContactText>
                                                    {!folderContactList.includes(contact) ?
                                                        <RemoveButton onClick={() => handleAddNumberInFolder(index)}>Add</RemoveButton>
                                                        :
                                                        <RemoveButton onClick={() => handleRemoveContact(folderContactList.indexOf(contact))}>Remove</RemoveButton>
                                                    }
                                                </ContactContainer>
                                                <UnderLine width={99} isPercent={true} />
                                            </>
                                        )) : <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>No Allowed user found.</div>}
                                    </NumberContainer>
                                    : null}
                            {isAlbum ?
                                <InputContainer>
                                    <CountryCodeInputContainer>
                                        <CountryCodeInput value={"+91"} type='text' readOnly />
                                        <UnderLine width={100} isPercent={true} />
                                    </CountryCodeInputContainer>
                                    <ContactInputContainer>
                                        <ContactInput type="number" value={enteredContact} placeholder='Enter phone number' onChange={(e) => {
                                            if (e.target.value.toString().length <= 10) setEnteredContact(e.target.value.toString());
                                        }} />
                                        <UnderLine width={100} isPercent={true} />
                                    </ContactInputContainer>

                                    <AddButtonContainer>
                                        <SubmitButton onClick={() => handleAddPhoneNumberAlbum()} text='Add' needArrow={false} width={80} height={40} />
                                    </AddButtonContainer>
                                </InputContainer>
                                : <div style={{ width: "100%", textAlign: "center", fontSize: "14px" }}>You can add new number by adding number in allowed user in album options.</div>}
                        </>
                    }
                </BodyContainer>
            </ModalContent>
        </ModalOverlay>
    )
}

export default AllowedUserModal;


const ModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
   z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  background-color: white;
color: black;
font-family: Urbanist,sans-serif;

  padding: 20px;
  border-radius: 5px;
  width: 500px;
  height:500px;
  max-width: 80%;
  position: relative;
`;


const CloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
`

const HeaderContainer = styled.div`
width:100%;
display:flex;
flex-direction: column;
text-align: center;
align-items: center;
justify-content: center;
margin-top:20px;
`

const Title = styled.h2`
  margin: 0;
  margin-left:2px;
`;

const SubTitle = styled.p`
    font-size:14px;
`;

const BodyContainer = styled.div`

`;

const ContactContainer = styled.div`
display:flex;
align-items: center;
justify-content: space-between;
`;

const ContactText = styled.p`
font-size:17px;
font-weight:700;
`;

const RemoveButton = styled.button`
padding:10px;
border:1px solid #AE2AB1;
background-color: transparent;
color: #AE2AB1;
cursor:pointer;
border-radius:16px;
min-width:75px;
`
const NumberContainer = styled.div`
height:300px;
padding:0px 9px;
overflow-y:auto;

`;


const InputContainer = styled.div`
    display: flex;
    border:1px solid gray;
    border-radius:16px;
    padding : 10px;

`


const NumberInputContainer = styled.div``;



const CountryCodeInputContainer = styled.div`
width:15%;

`;


const ContactInputContainer = styled.div`
width:60%;
margin-left:15px;
`;


const AddButtonContainer = styled.div`
margin-left:15px;
`;

const CountryCodeInput = styled.input`
width:100%;
border:none;
font-size:18px;
padding:5px;
&:focus{
outline:none;
}

`;

const ContactInput = styled.input`
width:100%;
font-size:18px;
padding:5px;
border:none;
&::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

&:focus{
outline:none;
}
`;



