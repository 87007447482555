import React from 'react'
import styled from 'styled-components'
import img from '../../../assets/images/kwikmediaLogo.png'

const FooterImageStyle = styled.img`
width:100%;
height:100%;
max-height:800px;
max-width:800px;
position: absolute;
bottom:-100px;
gap: 0px;
z-index:0;
opacity: 0.1;

`
const FooterImage: React.FC = () => {
  return (
    <FooterImageStyle src={img} />
  )
}

export default FooterImage