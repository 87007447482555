import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { UserState } from '../../Slice/Auth/AuthSlice';
import { getUserByToken } from '../../ApiCalls/Auth/login';
import { refreshAccessToken } from '../../ApiCalls/Auth/refreshToken';
import store from '../../Store';
import { clearError as clearAlbumError } from '../../Slice/Dashboard/AlbumSlice';
import { clearError as clearMemberError } from '../../Slice/Dashboard/MemberSlice'
import { getBusinessDetailsAPI, getUserActivePlanAPI, updateUserDetailsAPI, verifyEmailLinkAPI } from '../../ApiCalls/Auth/user';
import { showErrorToast, showSuccessToast } from '../../../components/atoms/Utlis/Toast';
import { ActivePlan, SubscriptionType } from '../../../Data/subscription.dto';



export const UserReducer = (builder: ActionReducerMapBuilder<UserState>) => {
    builder.addCase(getUserByToken.pending, (state) => {
        state.loading = true;
        state.error = {};
    })
        .addCase(getUserByToken.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            // console.log("payload")
            // console.log(action.payload)

            if (action.payload.role === null) {
                state.isError = true;
                state.error = {
                    status: 402,
                    message: "Please Register first"
                }
            } else if (action.payload.role !== 3) {
                state.isError = true;
                state.error = {
                    status: 401,
                    message: "You are not authorized to perform this action"
                }
            } else {
                state.user = action.payload;
                state.isUserChanged = false;
                state.access_token = localStorage.getItem('access_token');
                state.refresh_token = localStorage.getItem('refresh_token');
                state.isAuthenticated = true;
                state.apiStatus = true;
            }
        })
        .addCase(getUserByToken.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;

            // console.log(action.payload);
            state.error = action.payload;
        })
        .addCase(getBusinessDetailsAPI.pending, (state) => {
            state.loading = true;
            state.error = {};
            state.isError = false;

        })
        .addCase(getBusinessDetailsAPI.fulfilled, (state, action: PayloadAction<{
            business_details: {
                website: string,
                facebook_link: string,
                insta_link: string,
                youtube_link: string,
                watermark_link: string,
            }
        }>) => {
            state.loading = false;
            if (action.payload.business_details.website)
                state.SocialLinks.website = action.payload.business_details.website
            if (action.payload.business_details.facebook_link)
                state.SocialLinks.facebook_link = action.payload.business_details.facebook_link
            if (action.payload.business_details.insta_link)
                state.SocialLinks.insta_link = action.payload.business_details.insta_link
            if (action.payload.business_details.youtube_link)
                state.SocialLinks.youtube_link = action.payload.business_details.youtube_link
            if (action.payload.business_details.watermark_link)
                state.watermark = action.payload.business_details.watermark_link
            state.isSocialChanged = false
        })
        .addCase(getBusinessDetailsAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            // state.isError = true;
            state.isSocialChanged = false;
            // // console.log(action.payload);
            // state.error = action.payload;
        })
        .addCase(updateUserDetailsAPI.pending, (state) => {
            state.loading = true;
            state.error = {};
            state.isError = false;

        })
        .addCase(updateUserDetailsAPI.fulfilled, (state, action: PayloadAction<any>) => {
            showSuccessToast("User updated successfully.")
            state.isUserChanged = true;
            state.isSocialChanged = true;
        })
        .addCase(updateUserDetailsAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            // state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
            // // console.log(action.payload);
            // state.error = action.payload;
        })
        .addCase(verifyEmailLinkAPI.pending, (state) => {
            state.loading = true;
            state.error = {};
            state.isError = false;

        })
        .addCase(verifyEmailLinkAPI.fulfilled, (state, action: PayloadAction<any>) => {
            showSuccessToast("Verification email sent successfully.")
            state.loading = false;
        }).addCase(verifyEmailLinkAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            // state.isError = true;

            // // console.log(action.payload);
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
            // state.error = action.payload;
        }).addCase(getUserActivePlanAPI.pending, (state) => {
            state.loading = true;
            state.error = {};
            state.isError = false;

        })
        .addCase(getUserActivePlanAPI.fulfilled, (state, action: PayloadAction<{
            active_plans: ActivePlan[],
            expired_plans: ActivePlan[],
        }>) => {
            if (action.payload.active_plans.length > 0) {

                state.currentActivePlan = action.payload.active_plans[0];

            }
            if (action.payload.expired_plans.length > 0) {
                state.expiredPlan = action.payload.expired_plans;
            }
            state.loading = false;
            state.isCurrentPlanChanged = false;
        })
        .addCase(getUserActivePlanAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;

            state.isCurrentPlanChanged = false;
            // state.isError = true;

            // // console.log(action.payload);
            // state.error = action.payload;
        })
    // .addCase(refreshAccessToken.pending, (state) => {

    // })
    // .addCase(refreshAccessToken.fulfilled, (state, action: PayloadAction<any>) => {

    // })
    // .addCase(refreshAccessToken.rejected, (state, action: PayloadAction<any>) => {
    //     console.log(action.payload)
    //     // state.isError = true;    


    //     // // console.log(action.payload);
    //     // state.error = action.payload;
    // })

};