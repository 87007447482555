import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { MemberState } from '../../Slice/Dashboard/MemberSlice';
import { subscriptionsPlansAPI } from '../../ApiCalls/Dashboard/SubscriptionAPI';
import { ExtraState } from '../../Slice/Dashboard/ExtraSlice';
import { showErrorToast } from '../../../components/atoms/Utlis/Toast';
import { SubscriptionType } from '../../../Data/subscription.dto';
export const SubscriptionReducer = (builder: ActionReducerMapBuilder<ExtraState>) => {
    builder.addCase(subscriptionsPlansAPI.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.error = {};
    })
        .addCase(subscriptionsPlansAPI.fulfilled, (state, action: PayloadAction<SubscriptionType[]>) => {
            state.loading = false;
            state.isError = false;
            state.subscriptions = action.payload;
        })
        .addCase(subscriptionsPlansAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;

            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        })
};

// 
// "id": "18",
// "country_code": "+91",
// "phone_number": "8849827290",
// "profile_image": "https://res.cloudinary.com/dcuwbzynm/image/upload/v1718603255/gthlwzqyka7kcbsczocy.png",
// "name": "Pinkesh Patel",
// "job_type": "Camera Man",
// "user_id": null