import React, { useState } from 'react'
import styled from 'styled-components'
import UnderLine from '../../Login/UnderLine';
// import DatePickerComponent from './StyledDatePicker';
import DatePickerComponent from './StyledDatePicker';
import TimePickerComponent from './StyledTimePicker';
import { QuotationEventType } from '../../../../Data/quotation.dto';

interface QuotationEventModalPropsType {
  onCancel: () => void;
  eventData?: QuotationEventType;
  onSubmit: (data: QuotationEventType, isNew: boolean) => void;
  isNew?: boolean;
};

const QuotationEventModal: React.FC<QuotationEventModalPropsType> = ({ onCancel, eventData, onSubmit, isNew }) => {
  const [eventName, setEventName] = useState(eventData?.function_name);
  const [eventId, seEventId] = useState(eventData?.id)
  const [eventDate, setEventDate] = useState(eventData?.function_date);
  const [eventTime, setEventTime] = useState(eventData?.function_time);
  const [traditionalPhotographer, setTranditionalPhotographer] = useState<number>(eventData?.traditional_photographer ?? 0);
  const [traditionalVideographer, setTraditionalVideographer] = useState<number>(eventData?.traditional_videographer ?? 0);
  const [cinematicVideographer, setCinematicVideographer] = useState<number>(eventData?.cinematic_videographer ?? 0);
  const [candidPhotographer, setCandidPhotographer] = useState<number>(eventData?.candid_photographer ?? 0);
  const [drone, setDrone] = useState<number>(eventData?.drone ?? 0);
  const [showError, setShowError] = useState<boolean>(false);


  const onChangeData = (name: string, value: number) => {
    switch (name) {
      case "tp":
        setTranditionalPhotographer(value);
        break;
      case "tv":
        setTraditionalVideographer(value);
        break;
      case "cp":
        setCandidPhotographer(value);
        break;
      case "cv":
        setCinematicVideographer(value);
        break;
      case "drone":
        setDrone(value);
        break;
      default:
        break;
    }
  }
  const validData = (name: string) => {
    switch (name) {
      case "name":
        return eventName && eventName?.length > 0 && eventName.length < 50


      case "date":
        return eventDate && eventDate.length > 0;
      case "time":
        return eventTime && eventTime.length > 0;
      case "tp":
        return traditionalPhotographer >= 0 && traditionalPhotographer <= 20;
      case "tv":
        return traditionalVideographer >= 0 && traditionalVideographer <= 20;
      case "cp":
        return candidPhotographer >= 0 && candidPhotographer <= 20;
      case "cv":
        return cinematicVideographer >= 0 && cinematicVideographer <= 20;
      case "drone":
        return drone >= 0 && drone <= 10;
      default:
        return true;

    }
  }

  const handleSubmit = () => {
    if (eventName && validData("name") &&
      eventDate && validData("date") &&
      eventTime && validData("time") &&
      validData("tp") &&
      validData("tv") &&
      validData("cp") &&
      validData("cv") &&
      validData("drone")
    ) {
      console.log(eventId)
      onSubmit({
        id: eventId,
        function_name: eventName,
        function_date: eventDate,
        function_time: eventTime,
        traditional_photographer: traditionalPhotographer,
        traditional_videographer: traditionalVideographer,
        cinematic_videographer: cinematicVideographer,
        candid_photographer: candidPhotographer,
        drone

      }, isNew ? isNew : false)
      onCancel()
    } else {
      console.log(
        eventTime && validData("time")
      )
      setShowError(true);
    }
  }
  return (

    <Modal>
      <ModalContainer>
        <Container>
          <HeaderContainer>
            <HeaderText>
              New Event
            </HeaderText>
          </HeaderContainer>
          <BodyContainer>
            <InputNameContainer>
              <Label>Event Name</Label>
              <Input type="text" placeholder='Enter event name.' value={eventName} onChange={(e) => setEventName(e.target.value)} />
              <UnderLine width={100} isPercent={true} />
            </InputNameContainer>
            <EventDateTimeContainer>
              <EventDateContainer>
                <Label>Event Date</Label>
                <DatePickerComponent onChange={(data) => {
                  setEventDate(data)
                  console.log("event date data", data)
                }} dateValue={eventDate} />
              </EventDateContainer>
              <EventDateContainer>
                <Label>Event Time</Label>
                <TimePickerComponent onChange={(e) => setEventTime(e)} timeValue={eventTime} />
              </EventDateContainer>
            </EventDateTimeContainer>
            <ResourcesContainer>
              <SingleResourceContainer>
                <Label>Traditional Photographers</Label>
                <Input type="number" value={traditionalPhotographer} onChange={(e) => onChangeData("tp", parseInt(e.target.value))} />

              </SingleResourceContainer>
              <SingleResourceContainer>
                <Label>Traditional Videographer</Label>
                <Input type="number" value={traditionalVideographer} onChange={(e) => onChangeData("tv", parseInt(e.target.value))} />

              </SingleResourceContainer>
              <SingleResourceContainer>
                <Label>Candid Photographer</Label>
                <Input type="number" value={candidPhotographer} onChange={(e) => onChangeData("cp", parseInt(e.target.value))} />

              </SingleResourceContainer>
              <SingleResourceContainer>
                <Label>Cinematic Videographer</Label>
                <Input type="number" value={cinematicVideographer} onChange={(e) => onChangeData("cv", parseInt(e.target.value))} />

              </SingleResourceContainer>
              <SingleResourceContainer>
                <Label>Drone</Label>
                <Input type="number" value={drone} onChange={(e) => onChangeData("drone", parseInt(e.target.value))} />

              </SingleResourceContainer>
            </ResourcesContainer>
          </BodyContainer>
          <ButtonContainer>
            <CancelButton onClick={(e) => { e.stopPropagation(); onCancel() }}>Cancel</CancelButton>
            <SubmitButton onClick={(e) => { handleSubmit() }}>Submit</SubmitButton>
          </ButtonContainer>
        </Container>
      </ModalContainer>
    </Modal>
  )
}

export default QuotationEventModal

const Modal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 700px;
  height: 100%;
  max-height: 700px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 85%;
    max-height: 80%;
  }

  @media (max-width: 480px) {
    max-width: 80%;
    max-height: 90%;
    padding: 15px;
  }
`;

const Container = styled.div``;

const HeaderContainer = styled.div`
width:100%;
display:flex;
align-items: center;
justify-content: center;
`;


const HeaderText = styled.div`
 font-family: 'Urbanist', sans-serif;
  font-size: 22px;
  font-weight: 700;
`;


const BodyContainer = styled.div`
margin-top:50px;
padding:30px;
`;

const InputNameContainer = styled.div`
width:100%;
`;

const Label = styled.div`
font-family: 'Urbanist', sans-serif;
  font-size: 17px;
  font-weight: 500;
`;

const Input = styled.input`

margin-top: 7px;
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  width:100%;
  border: none;

&::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
`;


const ButtonContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-top:20px;
`;
const CancelButton = styled.button`
width:118px;
height:60px;
background: #EFEFEF;
border-radius:16px;
border:none;
font-family: Urbanist;
font-size: 20px;
font-weight: 700;
line-height: 24px;
text-align: center;
color:black;

cursor:pointer;
`;
const SubmitButton = styled.button`
width:118px;
height:60px;
background: linear-gradient(360deg, #7A11A1 0%, #C62BC9 100%);
border:none;
box-shadow: 0px 4px 18px 0px #A720B966;

border-radius:16px;
font-family: Urbanist;
font-size: 20px;
font-weight: 800;
line-height: 24px;
text-align: center;
color:white;
margin-left:10px;

cursor:pointer;
`;

const EventDateTimeContainer = styled.div`
display:flex;
margin-top:30px;
justify-content:space-between;
`;

const EventDateContainer = styled.div`
width:45%;
display:flex;
flex-direction:column;
`;

const EventTimeContainer = styled.div`
width:45%;
display:flex;
flex-direction:column;
`;


const ResourcesContainer = styled.div`
display:flex;
flex-wrap:wrap;
justify-content:space-between;
margin-top:30px;
`;

const SingleResourceContainer = styled.div`
width:45%;
margin-top:20px;
border-bottom:1px solid #dfdfdf;
padding-bottom:10px;

`;
