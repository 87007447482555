import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import UnderLine from '../../../atoms/Login/UnderLine'
import Errortext from '../../../atoms/Utlis/Errortext'
import { QuotationAlbumType } from '../../../../Data/quotation.dto';
interface QuotationAlbumDetailsPropsType {
    handlePreviousScreen: () => void;
    quotationAlbum?: QuotationAlbumType;
    handleNextScreen: () => void;
    handleQuotationAlbum: (data: QuotationAlbumType) => void;
}


const QuotationAlbumDetails: React.FC<QuotationAlbumDetailsPropsType> = ({
    handlePreviousScreen,
    quotationAlbum,
    handleNextScreen,
    handleQuotationAlbum
}) => {
    const [albumType, setAlbumType] = useState<string | undefined>(quotationAlbum?.album_type);
    const [albumSize1, setAlbumSize1] = useState<number>(0)
    const [albumSize2, setAlbumSize2] = useState<number>(0)
    const [albumPaper, setAlbumPaper] = useState<string | undefined>(quotationAlbum?.album_paper);
    const [albumPages, setAlbumPages] = useState<number>(quotationAlbum?.album_pages ?? 0)
    const [showError, setShowError] = useState(false);
    useEffect(() => {
        if (quotationAlbum) {
            if (quotationAlbum.album_size.split("X")[0]) {
                setAlbumSize1(parseInt(quotationAlbum.album_size.split("X")[0]))
            }
            if (quotationAlbum.album_size.split("X")[1]) {
                setAlbumSize2(parseInt(quotationAlbum.album_size.split("X")[1]))
            }
        }
    }, [])
    const onChangeData = (name: string, value: string, numValue?: number) => {
        switch (name) {
            case 'albumType':
                if (value.length <= 30) setAlbumType(value);
                break;
            case 'albumSize1':
                if (numValue) {

                    if (numValue.toString().length <= 3) {
                        setAlbumSize1(numValue);
                    }
                }

                else setAlbumSize1(0);
                break;
            case 'albumSize2':
                if (numValue) {

                    if (numValue.toString().length <= 3) {
                        setAlbumSize2(numValue);
                    }
                }

                else setAlbumSize2(0);
                break;
            case 'albumPaper':
                if (value.length <= 100) setAlbumPaper(value);
                break;
            case "albumPages":
                if (numValue) setAlbumPages(numValue);
                break;

            default:
                break;
        }
    };

    const validData = (key: string,) => {
        switch (key) {
            case 'albumType':
                return albumType && albumType?.length > 0;
            case 'albumSize1':
                return albumSize1 && albumSize1 > 0;
            case 'albumSize2':
                return albumSize2 && albumSize2 > 0;
            case 'albumPaper':
                return albumPaper && albumPaper.length > 0;
            case 'albumPages':
                return albumPages > 0
            default:
                return true;

        }

    }

    const onSubmit = () => {

        if (albumType) {
            if (validData("albumType") && albumPaper && validData("albumSize1") && validData("albumSize2") && validData("albumPaper") && validData("albumPages")) {
                console.log("called");
                handleQuotationAlbum({
                    album_type: albumType,
                    album_paper: albumPaper,
                    album_pages: albumPages,
                    album_size: `${albumSize1}X${albumSize2}`
                })
            } else {
                setShowError(true);
            }
        } else {
            handleNextScreen()
        }
    }
    return (
        <Container>
            <HeaderContainer>
                <HeaderText>Album Details</HeaderText>
            </HeaderContainer>
            <BodyContainer>
                <FormContainer>
                    <InputContainer>
                        <Label>Album Type : </Label>
                        <Input type='text' value={albumType} onChange={(e) => onChangeData("albumType", e.target.value)} />
                        <UnderLine width={100} isPercent={true} />
                        <Errortext show={showError && !validData("albumType")} message='Please provide valid album type' />
                    </InputContainer>
                    <InputContainer>
                        <Label>Album Size : </Label>
                        {/* <Input type='text' value={albumSize} onChange={(e) => onChangeData("albumSize", e.target.value)} />
                         */}
                        <AlbumSizeInputContainer>
                            <AlbumSizeInput type="number" value={albumSize1} onChange={(e) => onChangeData("albumSize1", '', parseInt(e.target.value))} />
                            <div style={{ fontSize: "18px", fontWeight: "600", margin: "0px 12px" }}>X</div>
                            <AlbumSizeInput type='number' value={albumSize2} onChange={(e) => onChangeData("albumSize2", '', parseInt(e.target.value))} />
                        </AlbumSizeInputContainer>
                        <UnderLine width={100} isPercent={true} />
                        <Errortext show={showError && !validData("albumSize1") && !validData("albumSize2")} message='Please provide valid album size' />
                    </InputContainer>
                    <InputContainer>
                        <Label>Album Paper : </Label>
                        <Input type='text' value={albumPaper} onChange={(e) => onChangeData("albumPaper", e.target.value)} />
                        <UnderLine width={100} isPercent={true} />
                        <Errortext show={showError && !validData("albumPaper")} message='Please provide valid album paper.' />
                    </InputContainer>
                    <InputContainer>
                        <Label>Album Pages : </Label>
                        <Input type='number' value={albumPages} onChange={(e) => onChangeData("albumPages", '', parseInt(e.target.value))} />
                        <UnderLine width={100} isPercent={true} />
                        <Errortext show={showError && !validData("albumPages")} message='Please provide valid album page.' />
                    </InputContainer>

                </FormContainer>

            </BodyContainer>
            <ButtonContainer>
                <PreviousButton onClick={() => handlePreviousScreen()}>Previous</PreviousButton>
                <NextButton onClick={() => onSubmit()}>Next</NextButton>
            </ButtonContainer>

        </Container>
    )
}

export default QuotationAlbumDetails;



const Container = styled.div``;

const HeaderText = styled.div`

font-family: Urbanist,sans-serif;
font-size: 24px;
font-weight: 600;
line-height: 22.8px;
text-align: center;
color: #171717;
margin:0px;
margin-left:11px;
`;

const HeaderContainer = styled.div`
width:100%;

`;

const FormContainer = styled.div`
display:flex;
flex-wrap:wrap;
margin-top:50px;
justify-content:space-between;
height:200px;

`;

const InputContainer = styled.div`
width:45%;
margin-bottom:40px;
display:flex;
flex-direction:column;
`;
const BodyContainer = styled.div`
height:580px;
`;
const Label = styled.label`
font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #424242;
  margin-bottom:10px;
`;

const Input = styled.input`
margin-top: 7px;
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;

  border: none;

&::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
`;

const ButtonContainer = styled.div`
width: 100%;
display:flex;
align-items: center;
justify-content: center;
font-family: 'Montserrat', sans-serif;
  
  line-height: 17.07px;
`;

const PreviousButton = styled.button`
width:120px;
padding:10px;
background-color:#c5c5c5;
color:white;
font-size: 18px;
  font-weight: 600;
  margin-right:30px;
border:none;
border-radius:15px;
cursor:pointer;
&:hover{
background-color:#adadad;
}

`;


const NextButton = styled.button`
width:120px;
padding:10px;
background-color:#AE2AB1;
color:white;
font-size: 18px;
  font-weight: 600;
  margin-right:30px;
border:none;
border-radius:15px;
cursor:pointer;
&:hover{
background-color:#c433c7;
}
`;


const AlbumSizeInput = styled.input`
margin-top: 7px;
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
width:42px;
border:none;
border-bottom:1px solid gray;
  

&::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
`;

const AlbumSizeInputContainer = styled.div`
display: flex;
align-items: center;
`;
