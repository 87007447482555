import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { QuotationPackageDetailsType } from '../../../../Data/quotation.dto'

interface QuotationPackageDetailsPropsType {
  handlePreviousScreen: () => void
  packageDetails?: QuotationPackageDetailsType
  handleNextScreen: () => void
  handleSubmit: (data: QuotationPackageDetailsType) => void
}
const QuotationPackageDetails: React.FC<QuotationPackageDetailsPropsType> = ({
  handleNextScreen,
  handlePreviousScreen,
  handleSubmit,
  packageDetails,
}) => {
  const [price, setPrice] = useState<number>()
  const [discount, setDiscount] = useState<number>(0)
  const [finalPrice, setFinalPrice] = useState<number>()
  const [productDetails, setProductDetails] = useState<string>()
  const [optionalData, setOptionalData] = useState<string>()
  const [tnc, setTnc] = useState<string>()
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  useEffect(() => {
    if (packageDetails) {
      setPrice(packageDetails.package_price);
      setDiscount(packageDetails.discount);
      setFinalPrice(packageDetails.total);
      if (packageDetails.product_details)
        setProductDetails(packageDetails.product_details)
      if (packageDetails.optional_data)
        setOptionalData(packageDetails.optional_data)
      if (packageDetails.terms_and_condition)
        setTnc(packageDetails.terms_and_condition)
    }
  }, [])
  useEffect(() => {
    if (price) {

      let finalCalculatedPrice = discount === 0 ? price : price - Math.round(price * (discount / 100));

      setFinalPrice(finalCalculatedPrice);
    } else {
      setFinalPrice(0)
    }
  }, [price, discount])

  useEffect(() => {
    if (isDataUpdated()) {

      setIsUpdated(true);
    } else {

      setIsUpdated(false);
    }
  }, [price, finalPrice, discount, optionalData, productDetails, optionalData, tnc])

  const onChangeData = (key: string, value: any) => {
    console.log(key, value)
    switch (key) {
      case 'price':
        setPrice(value)
        break
      case 'discount':
        if (value) {

          if (value <= 100) {


            setDiscount(value)
          }
        } else {
          setDiscount(0);
        }
        break
      case 'finalPrice':
        setFinalPrice(value)
        break
      case 'details':
        setProductDetails(value)
        break
      case 'od':
        setOptionalData(value)
        break
      case 'TnC':
        setTnc(value)
    }
  }
  const isValidData = (key: string) => {
    if (key === "price") {
      return price && price > 0;
    } else {
      return true;
    }
  }
  const isDataUpdated = (): boolean => {
    return (
      price !== packageDetails?.package_price ||
      discount !== packageDetails?.discount ||
      finalPrice !== packageDetails?.total ||
      productDetails !== packageDetails?.product_details ||
      optionalData !== packageDetails?.optional_data ||
      tnc !== packageDetails?.terms_and_condition
    );
  };
  const onSubmit = () => {
    if (price && isValidData("price") && finalPrice) {

      handleSubmit({
        package_price: price,
        discount: discount,
        total: finalPrice,
        product_details: productDetails,
        optional_data: optionalData,
        terms_and_condition: tnc
      });
    }
  }
  return (
    <Container>
      <HeaderContainer>
        <HeaderText>Details</HeaderText>
      </HeaderContainer>
      <BodyContainer>
        <PackageContainer>
          <Header>Package Details</Header>
          <PackageDataContainer>
            <InputContainer>
              <Label>Package Price</Label>
              <Input
                value={price}
                onChange={(e) =>
                  onChangeData('price', parseInt(e.target.value, 10))
                }
                type="number"
              />
            </InputContainer>
            <InputContainer>
              <Label>Discount (%)</Label>
              <Input
                type="number"
                value={discount.toString().replace(/^0+/, "") || "0"}
                onChange={(e) => {
                  const modifiedValue = e.target.value.toString().replace(/^0+/, "") || "0";
                  onChangeData('discount', parseInt(modifiedValue))
                }
                }
              />
            </InputContainer>
            <InputContainer>
              <Label>Final Price</Label>
              <Input
                value={finalPrice}
                onChange={(e) =>
                  onChangeData('finalPrice', parseInt(e.target.value))
                }
                type="number" />
            </InputContainer>
          </PackageDataContainer>
        </PackageContainer>
        <ExtraContainer>
          <Header>Extra Details</Header>
          <ExtraDataContainer>
            <InputContainer>
              <Label>Product Details :</Label>
              <TextArea
                value={productDetails}
                onChange={(e) =>
                  onChangeData('details', e.target.value)
                }
                placeholder='Enter Product Details...' cols={25} />
            </InputContainer>
            <InputContainer>
              <Label>Optional Details :</Label>
              <TextArea
                value={optionalData}
                placeholder='Enter Optional Details...'
                onChange={(e) =>
                  onChangeData('od', e.target.value)
                }
                cols={25} />
            </InputContainer>
          </ExtraDataContainer>
          <TandC_Container>
            <Label>Terms and Condition.</Label>
            <TextAreaTnC
              value={tnc}
              onChange={(e) =>
                onChangeData('TnC', e.target.value)
              }
              placeholder='Terms and Conditions ....'
              cols={25} />
          </TandC_Container>
        </ExtraContainer>
      </BodyContainer>
      <ButtonContainer>
        <PreviousButton onClick={() => handlePreviousScreen()}>
          Previous
        </PreviousButton>
        {isUpdated &&
          <NextButton onClick={() => onSubmit()}>Submit</NextButton>
        }
      </ButtonContainer>
    </Container>
  )
}

export default QuotationPackageDetails

const Container = styled.div``

const HeaderText = styled.div`
  font-family: Urbanist, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 22.8px;
  text-align: center;
  color: #171717;
  margin: 0px;
  margin-left: 11px;
`

const HeaderContainer = styled.div`
  width: 90%;
`

const BodyContainer = styled.div`
  height: 700px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
`

const PackageContainer = styled.div`
  width: 100%;
  border: 1px solid #d9d9d9;

  border-radius: 21px;
  padding-bottom: 40px;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
`

const Header = styled.div`
  width: 90%;
  font-family: Urbanist, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22.8px;
  text-align: center;
  color: #171717;
  margin: 0px;
`
const PackageDataContainer = styled.div`
  width: 98%;
  // border: 1px solid #d9d9d9;
  display: flex;
  padding: 0px;

  flex-wrap: wrap;
  // border-radius: 21px;
  justify-content: space-between;
  margin-top: 30px;
`
const InputContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  padding: 5px;
`
const Label = styled.label`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #424242;
`

const Input = styled.input`
  margin-top: 7px;
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;

  border: none;
  border-bottom: 1px solid gray;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
`

const ExtraContainer = styled.div`
  width: 100%;
  border: 1px solid #d9d9d9;
  margin-top: 30px;
  border-radius: 21px;
  padding-bottom: 40px;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
`

const ExtraDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const TextArea = styled.textarea`
  width: 96%;
  padding: 10px;
  border-radius: 15px;
  margin-top: 10px;
  height: 100px;
`

const TextAreaTnC = styled.textarea`
  width: 97%;
  padding: 10px;
  border-radius: 15px;
  margin-top: 10px;
  height: 100px;
`

const TandC_Container = styled.div``

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;

  line-height: 17.07px;
`

const PreviousButton = styled.button`
  width: 120px;
  padding: 10px;
  background-color: #c5c5c5;
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-right: 30px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background-color: #adadad;
  }
`

const NextButton = styled.button`
  width: 120px;
  padding: 10px;
  background-color: #ae2ab1;
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-right: 30px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background-color: #c433c7;
  }
`
