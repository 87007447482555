import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { AlbumState, UpdatedFolderType, removeFromUpdatingFolderProgress } from '../../Slice/Dashboard/AlbumSlice';
import { createFolderAPI, deleteFolderAPI, deleteFolderImagesAPI, getSingleFolderAPI, hideFolderAPI, lockFolderAPI, lockMultipleFoldersAPI, toggleWatermarkAPI, unlockFolderAPI, updateFolderAPI, updateFolderNameAPI } from '../../ApiCalls/Dashboard/FolderApi';
import { Folder } from '../../../Data/album.dto';
import { showErrorToast, showSuccessToast } from '../../../components/atoms/Utlis/Toast';
import store from '../../Store';

export const FolderReducer = (builder: ActionReducerMapBuilder<AlbumState>) => {

    builder.addCase(lockMultipleFoldersAPI.pending, (state) => {
        state.folderLoading = true;
    })
        .addCase(lockMultipleFoldersAPI.fulfilled, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isFolderChange = true;
            showSuccessToast("Folders successfully locked.");
        })
        .addCase(lockMultipleFoldersAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        })
        .addCase(unlockFolderAPI.pending, (state) => {
            state.folderLoading = true;
        })
        .addCase(unlockFolderAPI.fulfilled, (state, action: PayloadAction<{ folder_id: number, project_id: number }>) => {
            state.isFolderChange = true;
            state.folderLoading = false;
            state.updatedFolderList.push(action.payload)
            showSuccessToast("Folder successfully unlocked.");
        })
        .addCase(unlockFolderAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        })
        .addCase(createFolderAPI.pending, (state) => {
            state.folderLoading = true;
        })
        .addCase(createFolderAPI.fulfilled, (state, action: PayloadAction<{ folder_id: number, project_id: number }>) => {
            state.updatedFolderList.push(action.payload);
            state.folderLoading = false;
            showSuccessToast("Folder is successfully created.")
        })
        .addCase(createFolderAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        })
        .addCase(updateFolderAPI.pending, (state) => {

        })
        .addCase(updateFolderAPI.fulfilled, (state, action: PayloadAction<{ folder_id: number, project_id: number }>) => {
            state.updatedFolderList.push(action.payload);
            state.uploadFolderProgress = state.uploadFolderProgress.filter(folder => folder.folderId !== action.payload.folder_id)
            showSuccessToast("Folders updated successfully.");
        })
        .addCase(updateFolderAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            state.uploadFolderProgress = state.uploadFolderProgress.filter(folder => folder.folderId !== action.payload.folder_id)


        })
        .addCase(getSingleFolderAPI.pending, (state) => {
            state.folderLoading = true;
        })
        .addCase(getSingleFolderAPI.fulfilled, (state, action: PayloadAction<{ folder: Folder, page: number }>) => {
            state.isFolderChange = false;
            state.folderLoading = false;
            state.updatedFolderList = state.updatedFolderList.filter(folder => folder.folder_id !== action.payload.folder.id)
            // state.currentFolder = action.payload.folder;
            if (state.currentFolder) {
                if (action.payload.page === 1 && action.payload.folder && state.currentAlbum) {
                    state.currentAlbum.folders = state.currentAlbum?.folders?.map(folder => {
                        if (folder.id === action.payload.folder.id) {
                            return action.payload.folder;
                        }
                        return folder;
                    })
                    state.currentFolder.name = action.payload.folder.name;
                    state.currentFolder.images = [...action.payload.folder.images];
                }
                else {

                    state.currentFolder.images = [...state.currentFolder.images, ...action.payload.folder.images];
                }
            }
        })
        .addCase(getSingleFolderAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            state.isFolderChange = false;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        })
        .addCase(deleteFolderImagesAPI.pending, (state) => {
            state.folderLoading = true;
        })
        .addCase(deleteFolderImagesAPI.fulfilled, (state, action: PayloadAction<{ folder_id: number, project_id: number }>) => {
            state.updatedFolderList.push(action.payload)
            state.folderLoading = false;
            showSuccessToast("Image deleted successfully.");

        })
        .addCase(deleteFolderImagesAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        })
        .addCase(deleteFolderAPI.pending, (state) => {
            state.folderLoading = true;
        })
        .addCase(deleteFolderAPI.fulfilled, (state, action: PayloadAction<{ folder_id: number, project_id: number }>) => {
            state.folderLoading = false;
            state.updatedFolderList.push(action.payload)
            showSuccessToast("Folder Deleted.");


        })
        .addCase(deleteFolderAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        })
        .addCase(hideFolderAPI.pending, (state) => {
            state.folderLoading = true;
        })
        .addCase(hideFolderAPI.fulfilled, (state, action: PayloadAction<{ folder_id: number, project_id: number }>) => {
            state.updatedFolderList.push(action.payload)
            state.folderLoading = false;
            showSuccessToast("Folder's status updated successfully.");
        })
        .addCase(hideFolderAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        }).addCase(lockFolderAPI.pending, (state) => {
            state.folderLoading = true;
        }).addCase(lockFolderAPI.fulfilled, (state, action: PayloadAction<{ folder_id: number, project_id: number }>) => {
            state.updatedFolderList.push(action.payload)
            state.folderLoading = false;
            showSuccessToast("Folder's status updated successfully.");
        })
        .addCase(lockFolderAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        }).addCase(toggleWatermarkAPI.pending, (state) => {
            state.folderLoading = true;
        }).addCase(toggleWatermarkAPI.fulfilled, (state, action: PayloadAction<{ folder_id: number, project_id: number }>) => {
            state.updatedFolderList.push(action.payload)
            state.folderLoading = false;
            showSuccessToast("Watermark status updated.");
        })
        .addCase(toggleWatermarkAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        }).addCase(updateFolderNameAPI.pending, (state) => {
            state.folderLoading = true;
        }).addCase(updateFolderNameAPI.fulfilled, (state, action: PayloadAction<{ folder_id: number, project_id: number }>) => {

            state.folderLoading = false;
            state.updatedFolderList.push(action.payload);
            state.uploadFolderProgress = state.uploadFolderProgress.filter(folder => folder.folderId !== action.payload.folder_id)

            showSuccessToast("folder name  updated.");
        })
        .addCase(updateFolderNameAPI.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.isError = true;
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        });
};

