import React from "react";
import styled from "styled-components";
import LogoImage from "../../atoms/Utlis/LogoImage";
import { useNavigate } from "react-router-dom";



const LogoContainer = styled.div`


  gap: 0px;
  opacity: 0px;
  margin-top: 10px;
  display:flex;
  flex-direction:row;
`;
const CompanyLogo: React.FC = () => {
  const navigate = useNavigate();
  return (
    <LogoContainer onClick={() => navigate('/dashboard/')}>
      <LogoImage />
    </LogoContainer>
  );
};

export default CompanyLogo;
