import React, { useEffect, useRef, useState } from 'react'
import { Modal, Button, Tabs, Upload } from 'antd';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/es/upload';
import { showErrorToast } from '../../Utlis/Toast';

const { TabPane } = Tabs;

interface UploadImageModalComponentPropsType {
    handleImageUpload: (files: File[]) => void;
    closeModal: () => void;
}

const UploadImageModalComponent: React.FC<UploadImageModalComponentPropsType> = ({ closeModal, handleImageUpload }) => {
    const [uploadedFiles, setUploadedFiles] = useState<Set<string>>(new Set());
    const fileInputRef = useRef<HTMLInputElement>(null);


    const handleModalImageUpload = async (info: UploadChangeParam<any>) => {
        const files = info.fileList.map((file) => file.originFileObj).filter(Boolean) as File[];

        if (!files || files.length === 0) return;

        // Filter unique files
        const uniqueFiles = files.filter(file => {
            if (uploadedFiles.has(file.name)) {
                return false; // Skip file if it's already uploaded
            } else {
                uploadedFiles.add(file.name); // Add to set if it's new
                return true; // Include file in unique files
            }
        });

        if (uniqueFiles.length > 0) {
            handleImageUpload(uniqueFiles);
        }



    };
    const handleFolderSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);

        if (files.length === 0 && files.length > 4000) {
            showErrorToast("Too many files detected.");
            return;
        };

        // Filter out non-image files
        const imageFiles = files.filter((file) => {
            return file.type.startsWith('image/');
        });

        // Filter out duplicate files
        const uniqueFiles = imageFiles.filter((file) => {
            if (uploadedFiles.has(file.name)) {
                return false; // Skip if already selected
            } else {
                uploadedFiles.add(file.name); // Mark file as uploaded
                return true;
            }
        });
        // Pass unique files back to parent component
        console.log("unique files", uniqueFiles)
        if (uniqueFiles.length > 0) {
            handleImageUpload(uniqueFiles);
        }

        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the input to clear the selected files
        };


    };
    return (
        <Container>
            <StyledTabs defaultActiveKey="1">
                {/* Tab 1: Upload Image */}
                <TabPane tab="Upload Image" key="1">
                    <TabContent>
                        <Upload accept="image/*" showUploadList={false} multiple onChange={handleModalImageUpload} beforeUpload={() => false}>
                            <StyledUploadButton icon={<UploadOutlined />}>Click to Upload Image</StyledUploadButton>
                        </Upload>
                    </TabContent>
                </TabPane>

                {/* Tab 2: Upload Folder */}
                <TabPane tab="Upload Folder" key="2">
                    <TabContent>
                        <StyledUploadButton icon={<UploadOutlined />} onClick={() => { fileInputRef.current?.click() }}>Click to Upload Folder</StyledUploadButton>
                        <input
                            ref={fileInputRef} // Attach the ref to the input
                            type="file"
                            // ts-ignore
                            {...({ webkitdirectory: 'true' } as any)}
                            multiple
                            style={{ display: "none" }}
                            onChange={handleFolderSelection}
                        />
                    </TabContent>
                </TabPane>
            </StyledTabs>
        </Container>
    )
}

export default UploadImageModalComponent


const Container = styled.div``;


// Styled Tabs component
const StyledTabs = styled(Tabs)`
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #AE2AB1 !important; /* Set active tab color (e.g., blue) */
  }

  .ant-tabs-ink-bar {
    background-color: #AE2AB1 !important; /* Customize the tab indicator color */
  }
    .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: #AE2AB1 !important; /* Hover color (e.g., green) */
  }
`;

// Styled Tab content wrapper
const TabContent = styled.div`
  text-align: center;
  padding: 20px;
`;

// Styled Upload Button
const StyledUploadButton = styled(Button)`
  margin-top: 20px;
  transition: all 0.3s ease;

  &:hover {
  color: #AE2AB1 !important;
    border-color: #AE2AB1 !important; /* Change border color on hover */
    // outline: 1px solid #AE2AB1;       /* Add a custom outline color on hover */
    box-shadow: 0 0 10px rgba(174, 42, 177, 0.5); /* Optional: Add shadow for effect */
  }
`;
