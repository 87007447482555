import { setCalendarEventFlag, setPackageFlag, setQuotationFlag, setUserEventFlag, setUserTransactionFlag } from '../Redux/Slice/Dashboard/FlagSlice';
import store from '../Redux/Store';
import { remoteConfig } from '../firebase'
import { getValue } from "firebase/remote-config";
remoteConfig.settings.minimumFetchIntervalMillis = 360000

import { fetchAndActivate } from "firebase/remote-config";



export const fetchAllRemoteConfig = () => {
    fetchAndActivate(remoteConfig)
        .then(() => {
            let calendarEventValue = getValue(remoteConfig, "CalendarEvent").asBoolean();
            let PackageFlag = getValue(remoteConfig, "Package").asBoolean();
            let UserEventFlag = getValue(remoteConfig, "UserEvent").asBoolean();
            let UserTransactionFlag = getValue(remoteConfig, "UserTransaction").asBoolean();
            let QuotationFlag = getValue(remoteConfig, "Quotation").asBoolean();
            if (calendarEventValue) {
                store.dispatch(setCalendarEventFlag({ value: calendarEventValue }))
            }

            if (PackageFlag) {
                store.dispatch(setPackageFlag({ value: PackageFlag }))
            }

            if (UserEventFlag) {
                store.dispatch(setUserEventFlag({ value: UserEventFlag }))
            }
            if (UserTransactionFlag) {
                store.dispatch(setUserTransactionFlag({ value: UserTransactionFlag }))
            }
            if (QuotationFlag) {
                store.dispatch(setQuotationFlag({ value: UserTransactionFlag }))
            }

        })
        .catch((err) => {
            // ...
            console.log("error in flags", err)
        });

}