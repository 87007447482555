import React, { useState } from 'react';
import styled from 'styled-components';
import { TimePicker } from 'antd';

import dayjs from 'dayjs';
const StyledTimePicker = styled(TimePicker)`
  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #AE2AB1;
    color: white;
  }
`;

interface TimePickerComponentPropsType {
    onChange: (value: string) => void;
    timeValue?: string;
}

const TimePickerComponent: React.FC<TimePickerComponentPropsType> = ({ onChange, timeValue }) => {

    const formatDateString = (timeString: string | undefined) => {
        console.log(timeString ? dayjs(timeString, 'HH:mm:ss') : null)
        return timeString ? dayjs(timeString, 'HH:mm:ss') : null;
    };
    return (
        <StyledTimePicker
            onChange={(time: any) => {
                if (time)
                    onChange(time.format("HH:mm:ss"));
            }
            }
            value={formatDateString(timeValue)}
            placeholder="Select Time"
            format="HH:mm"
        />
    );
};

export default TimePickerComponent;