import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { AlbumState } from '../../Slice/Dashboard/AlbumSlice';
import { ActivateGuestFlow, getAllRedeemUserAPI, getLIkedImagesAPI, lockAlbum, removeRedeemUserAPI, unlockAlbum } from '../../ApiCalls/Dashboard/AlbumAPI';
import { RedeemUsers, User } from '../../../Data/user.dto';
import { showErrorToast, showSuccessToast } from '../../../components/atoms/Utlis/Toast';
import { LikedImageType } from '../../../Data/album.dto';
export const AlbumOptionReducer = (builder: ActionReducerMapBuilder<AlbumState>) => {

    builder.addCase(lockAlbum.pending, (state) => {
        state.loading = true;
    })
        .addCase(lockAlbum.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isUpdate = true;
            showSuccessToast("Album status updated.")
        })
        .addCase(lockAlbum.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;
            state.isUpdate = false
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }


        }).addCase(unlockAlbum.pending, (state) => {
            state.loading = true;
            state.folderLoading = true;
        })
        .addCase(unlockAlbum.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isUpdate = true;
            state.folderLoading = false;
        })
        .addCase(unlockAlbum.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.loading = false;
            state.isError = true;
            state.isUpdate = false
            // console.log(action.payload);
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        }).addCase(getAllRedeemUserAPI.pending, (state) => {
            state.loading = true;
            state.isError = false;
        })
        .addCase(getAllRedeemUserAPI.fulfilled, (state, action: PayloadAction<RedeemUsers[]>) => {
            state.loading = false;
            state.redeemUsers = action.payload;
            state.isRedeemUserUpdates = false;

        })
        .addCase(getAllRedeemUserAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;
            state.redeemUsers = [];

            // console.log(action.payload);
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        }).addCase(removeRedeemUserAPI.pending, (state) => {
            state.loading = true;
            state.isError = false;
        })
        .addCase(removeRedeemUserAPI.fulfilled, (state, action: PayloadAction<User[]>) => {
            state.loading = false;
            state.isRedeemUserUpdates = true;;
            // state.redeemUsers = action.payload;

        })
        .addCase(removeRedeemUserAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;
            // console.log(action.payload);
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        }).addCase(getLIkedImagesAPI.pending, (state) => {
            state.loading = true;
            state.isError = false;
        })
        .addCase(getLIkedImagesAPI.fulfilled, (state, action: PayloadAction<{
            count: number,
            results: {
                project: string,
                media_id: LikedImageType
            }[]
        }>) => {
            state.loading = false;
            if (action.payload) {
                let { count, results } = action.payload
                if (count > 0) {
                    let project_id = results[0].media_id.project_id;
                    if (state.currentAlbum && state.currentAlbum.id === project_id) {
                        state.currentAlbum.liked_images = []
                        for (let i = 0; i < results.length; i++) {
                            state.currentAlbum.liked_images.push(results[i].media_id);
                        }
                    }
                    for (let i = 0; i < state.albums.length; i++) {
                        if (state.albums[i].id === state.currentAlbum?.id) {
                            state.albums[i].liked_images = state.currentAlbum.liked_images;
                        }

                    }
                }
                else {
                    if (state.currentAlbum)
                        state.currentAlbum.liked_images = [];
                }
            } else {
                if (state.currentAlbum)
                    state.currentAlbum.liked_images = [];
            }
            // state.redeemUsers = action.payload;

        })
        .addCase(getLIkedImagesAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isError = true;

            // console.log(action.payload);
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        }).addCase(ActivateGuestFlow.pending, (state) => {
            state.loading = true;
            state.folderLoading = true;
        })
        .addCase(ActivateGuestFlow.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.isUpdate = true;
            state.folderLoading = false;
            showSuccessToast("Guest flow status updated.")
        })
        .addCase(ActivateGuestFlow.rejected, (state, action: PayloadAction<any>) => {
            state.folderLoading = false;
            state.loading = false;
            state.isError = true;
            state.isUpdate = false
            // console.log(action.payload);
            if (action.payload && action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                console.log(action.payload)
            }
        })


};