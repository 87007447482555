import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import UnderLine from '../../Login/UnderLine';
import Errortext from '../../Utlis/Errortext';
/* Modal styles */
const Modal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 85%;
    max-height: 80%;
  }

  @media (max-width: 480px) {
    max-width: 80%;
    max-height: 90%;
    padding: 15px;
  }
`;

/* Map container styles */
const MapContainer = styled.div`
  height: 400px;
  width: 100%;

  @media (max-width: 480px) {
    height: 300px;
  }
`;

/* Button styles */
const SubmitButton = styled.button`
  width: 200px;
  border: none;
  border-radius: 16px;
  font-family: Urbanist, sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 19.2px;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  height: 54px;
  color: #FFFFFF;
  background: linear-gradient(360deg, #7A11A1 0%, #C62BC9 100%);
  box-shadow: 0px 4px 14px 0px #86169680;

  @media (max-width: 780px) {
    padding: 8px;
    font-size: 14px;
    margin:0;
    margin-bottom:10px;
  }
`;

const CancelButton = styled.button`
  width: 200px;
  border: none;
  border-radius: 16px;
  font-family: Urbanist, sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 19.2px;
  text-align: center;
  cursor: pointer;
  height: 54px;
  color: black;
  background: gray;
  box-shadow: 0px 4px 14px 0px #86169680;

  @media (max-width: 480px) {
    padding: 8px;
    font-size: 14px;
  }
`;

/* Submit container styles */
const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

/* Input container styles */
const InputContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 400px;
  flex-direction: column;
  align-items: baseline;
  height: 90%;
`;

const InputSocialContainer = styled.div`
  display: flex;
  width: 87%;
  min-width: 400px;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 16px;
`;

const InputContainerDescription = styled.div`
  display: flex;
  width: 79%;
  flex-direction: column;
  align-items: baseline;
`;

const InputLabel = styled.label`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #969595;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  margin-top: 5px;
  color: #292929;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

/* Header title styles */
const HeaderTitle = styled.h2`
  width: 100%;
  text-align: center;
`;

const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

interface LocationPickerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (locationString: string) => void;
}

const LocationPickerModal: React.FC<LocationPickerModalProps> = ({ isOpen, onClose, onSelect }) => {
  const [selectedPosition, setSelectedPosition] = useState<{ lat: number; lng: number } | null>({ lat: 21.181119927085486, lng: 72.79392214369257 });
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [showError, setShowError] = useState(false);

  const handleMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setSelectedPosition({ lat, lng });

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          setInputValue(results[0].formatted_address);
        } else {
          setInputValue('Location not found');
        }
      });
    }
  }, []);

  const onLoadAutocomplete = (autocompleteInstance: google.maps.places.Autocomplete) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location?.lat();
        const lng = place.geometry.location?.lng();
        if (lat && lng) {
          setSelectedPosition({ lat, lng });
          setInputValue(place.formatted_address || '');
        }
      }
    }
  };

  const handleSelectLocation = () => {
    // if (selectedPosition) {
    //     const locationString = `${selectedPosition.lat}, ${selectedPosition.lng}`;
    //     onSelect(locationString);
    // }
    if (inputValue.length > 0)
      onSelect(inputValue);
    // console.log(inputValue)
  };

  return (
    isOpen ? (
      <Modal>
        <ModalContainer>
          <HeaderTitle>Select Location</HeaderTitle>

          <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged}>
            <InputContainer>
              <InputLabel>Location : </InputLabel>
              <Input value={inputValue} type='text' onChange={(e) => setInputValue(e.target.value)} />
              <UnderLine width={100} isPercent={true} />
              <Errortext show={showError && inputValue.length === 0} message={'Please provide valid location.'} />
            </InputContainer>
          </Autocomplete>
          <MapContainer>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={selectedPosition || center}
              zoom={10}
              onClick={handleMapClick}
            >
              {selectedPosition && <Marker position={selectedPosition} />}
            </GoogleMap>
          </MapContainer>


          {/* <InputContainer>
            <InputLabel>Location : </InputLabel>
            <Input value={inputValue} type='text' onChange={(e) => setInputValue(e.target.value)} />
            <UnderLine width={100} isPercent={true} />
            <Errortext show={showError && inputValue.length === 0} message={'Please provide valid location.'} />
          </InputContainer> */}
          <SubmitContainer>
            <SubmitButton onClick={() => handleSelectLocation()}>Select Location</SubmitButton>
            <CancelButton onClick={onClose}>Close</CancelButton>
          </SubmitContainer>
        </ModalContainer>
      </Modal>
    ) : null
  );
};

export default LocationPickerModal;
