import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Transaction } from '../../../Data/package.dto'
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks'
import { getAllPackageTransactions } from '../../../Redux/ApiCalls/Dashboard/PackageAPI'

const AllCustomerTransactionPage = () => {
  const { transactions } = useAppSelector((state) => state.package)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getAllPackageTransactions())
  }, [])
  const formatToINR = (amount: number): string => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>Transactions</HeaderText>
      </HeaderContainer>
      <BodyContainer>
        {transactions && transactions.length > 0 ?

          <TableContainer>
            <StyledTable>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Contact Number</th>
                  <th>Title</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((item, index) => (
                  <tr key={index}>
                    <td>{item.customer_name}</td>
                    <td>{`${item.country_code} ${item.customer_phone_number}`}</td>
                    <td>{item.title}</td>
                    <td>{formatToINR(item.price)}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </TableContainer> :
          <div style={{ width: "100%", height: "800px", display: "flex", alignItems: "center", justifyContent: "center" }}>No transaction has been done.</div>
        }
      </BodyContainer>
    </Container>
  )
}

export default AllCustomerTransactionPage

const Container = styled.div``
const HeaderContainer = styled.div`
width:100%:
`

const HeaderText = styled.p`
  font-family: Urbanist, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 22.8px;
  text-align: center;
  color: #171717;
  margin: 0px;
`
const BodyContainer = styled.div`
  margin: 30px;
  // width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TableContainer = styled.div`
  max-height: 800px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0;
  width: 93%;
`

const StyledTable = styled.table`
  width: 98%;
  border-collapse: collapse;
  thead {
    background-color: transparent;
  }
  th,
  td {
    padding: 12px;
    background-color: transparent;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-family: Urbanist, sans-serif;
    line-height: 22.8px;
    text-align: center;
    color: #171717;
    margin: 0px;
  }

  th {
    font-size: 20px;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  td {
    font-size: 18px;
    font-weight: 500;
  }
`
