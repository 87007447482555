import React from 'react'
import styled from 'styled-components'
import KwikMediaLogo from '../../../assets/images/kwikmediaLogo.png';


const LogoImageContainer = styled.img`
width: 150px;
height: 150px;
gap: 0px;
opacity: 0px;

`

const LogoImage: React.FC = () => {
    return (
        <LogoImageContainer src={KwikMediaLogo} />
    )
}

export default LogoImage